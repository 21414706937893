import { post } from './io';
import { RunCondition } from '../domain/InfraCommand';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const reboot = async (resourceId: string): Promise<void> => {
  const url = rootUrl + '/base_api/infracommand/reboot';
  const params = { resourceId };
  await post(url, params);
  return Promise.resolve();
};

export const terminate = async (resourceId: string): Promise<void> => {
  const url = rootUrl + '/base_api/infracommand/terminate';
  const params = { resourceId };
  await post(url, params);
  return Promise.resolve();
};

export const run = async (cond: RunCondition): Promise<void> => {
  const url = rootUrl + '/base_api/infracommand/run';
  const params = { instance: cond };
  await post(url, params);
  return Promise.resolve();
};
