import React, { useEffect, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Props<T> {
  id: keyof T;
  value: string[];
  label: string;
  onChange: (id: keyof T, value: any) => void;
}

const checkboxNames = ['2xx', '3xx', '4xx', '5xx'];

type HttpStatusSelectorType = <T>(
  props: Props<T>
) => React.ReactElement<Props<T>>;

/**
 * Httpステータスコードのための検索条件指定コンポーネント
 * @param props
 */
export const HttpStatusSelector: HttpStatusSelectorType = (props) => {
  const classes = useStyles();

  /**
   * このコンポーネントとしてのvalue
   * propsからの入力を初期値として、このコンポーネント内で管理する。
   */
  const [value, setValue] = useState(props.value);

  /**
   * 現在の入力からスペース繋ぎの文字列を作る
   */
  const checkCurrent = () => {
    let selected = '';

    checkboxNames.forEach((name) => {
      const box = document.getElementsByName(name)[0] as HTMLInputElement;
      if (box.checked) {
        selected += name + ' ';
      }
    });

    if (selected === '') {
      setValue([]);
      return;
    }

    const text = document.getElementsByName('freespace')[0] as HTMLInputElement;
    selected += text.value;

    setValue(selected.trim().split(' '));
  };

  /**
   * valueに変化があったら、Inputイベントを発行する
   */
  useEffect(() => {
    const e = new Event('input', { bubbles: true });
    document.getElementById(props.id as string)?.dispatchEvent(e);
  }, [value, props.id]);

  /**
   * Inputイベントを親に伝搬する
   * @param e ChangeEvent
   */
  const sendParent = (input: string[]) => {
    props.onChange(props.id, input);
  };

  return (
    <div className={classes.container}>
      <FormLabel component="legend">status</FormLabel>
      <input
        type="hidden"
        id={props.id as string}
        value={value}
        onInput={() => sendParent(value)}
      />

      <FormGroup row onChange={checkCurrent}>
        {checkboxNames.map((name) => {
          return (
            <FormControlLabel
              key={name}
              control={<Checkbox name={name} />}
              label={name}
            />
          );
        })}
        <TextField name="freespace"></TextField>
      </FormGroup>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 20,
    },
  })
);
