import React from 'react';
import { News } from '../../domain/NewsStack';
import { dateFormat } from '../../domain/dateFormat';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

type Props = {
  /**
   * お知らせ一覧の表示データ
   */
  data: News[];

  /**
   * 詳細情報を表示するためのインターフェース
   */
  openDetail: (notificationId: number) => void;
};

export const List: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">NotificationId</TableCell>
              <TableCell align="right">Context</TableCell>
              <TableCell align="right">Message</TableCell>
              <TableCell align="right">LinkURL</TableCell>
              <TableCell align="right">NotifiedAt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((d, i) => (
              <TableRow
                key={d.notificationId}
                hover
                onClick={() => props.openDetail(props.data[i].notificationId)}
              >
                <TableCell component="th" scope="row">
                  {d.notificationId}
                </TableCell>
                <TableCell align="right">{d.context}</TableCell>
                <TableCell align="right">{d.message}</TableCell>
                <TableCell align="right">{d.linkUrl}</TableCell>
                <TableCell align="right">{dateFormat(d.notifiedAt)}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() =>
                      props.openDetail(props.data[i].notificationId)
                    }
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
