import { post } from './io';
import { AMI, SearchCondition as AMISearchCondition } from '../domain/AMI';
import {
  Resource,
  SearchCondition as ResourceSearchCondition,
  Alive,
} from '../domain/InfraResource';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const amiList = async (
  cond: AMISearchCondition
): Promise<Array<AMI>> => {
  const url = rootUrl + '/base_api/inframonitor/ami/list';
  const params = cond;
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const amiDescribe = async (amiId: string): Promise<AMI> => {
  const url = rootUrl + '/base_api/inframonitor/ami/describe';
  const params = {
    amiId,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const resourceList = async (
  cond: ResourceSearchCondition
): Promise<Array<Resource>> => {
  const url = rootUrl + '/base_api/inframonitor/resource/list';
  const params = cond;
  await post(url, params);
  return Promise.resolve(dummyResources);
};

export const resourceDescribe = async (
  resourceId: string
): Promise<Resource> => {
  const url = rootUrl + '/base_api/inframonitor/resource/describe';
  const params = {
    resourceId,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const aliveDescribe = async (resourceId: string): Promise<Alive> => {
  const url = rootUrl + '/base_api/inframonitor/alive/describe';
  const params = {
    resourceId,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const dummyResources: Resource[] = [
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E18GML2MUULRRJ',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E1AEHH972NF8PQ',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'arn:aws:elasticloadbalancing:ap-northeast-1:294756904836:loadbalancer/app/libra-demo01/4b948d2735b469dd',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E1AY4R9ACGZM7C',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'PlatonELB-1910390646.us-west-2.elb.amazonaws.com',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E1H7GAY91GO9X6',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-General-cf' }],
    levels: [
      {
        downstreamResourceId:
          'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Xe-Internet-alb/3af85c5e15eb93dc',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E1OMX4EJB7ZINQ',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'PlatonELB-1910390646.us-west-2.elb.amazonaws.com',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E1S0MO89Z2BE8P',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E20EA8H8UIKGZS',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/xe-cluster-alb-admin/f032e5c1c9404c09',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E24YOWSZZ72T6R',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-0692dc39359352b74' }],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E2979DZNX8OST4',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E29C6XXB4UUPH8',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-0595b2e16aeecf31e' }],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E2UWSAAQL8X3BL',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'DevSaaSELB-1929609090.us-west-2.elb.amazonaws.com',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E2WOVXHYMFI711',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Platon1100/2480b750813a9f1d',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E35JQWQVY3FO1',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'PlatonELB-1910390646.us-west-2.elb.amazonaws.com',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/E3AVYKKYPP1R1G',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      { downstreamResourceId: 'i-0dcd6e2b8303069c8' },
      {
        downstreamResourceId: 'LibraELB-184677599.us-west-2.elb.amazonaws.com',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/EFHZEKGESYB59',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/EKBYDYI14E2ME',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [
      {
        downstreamResourceId:
          'PlatonELB-1910390646.us-west-2.elb.amazonaws.com',
      },
    ],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/ERJFUS19MCFYB',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/EY5W90LXOUIVR',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-0b722b5066547f6a6' }],
  },
  {
    resourceId: 'arn:aws:cloudfront::294756904836:distribution/EYJ6O9JK21DD1',
    resourceType: 'cf',
    monitoredAt: '2020-08-17T07:51:52Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-0b722b5066547f6a6' }],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:ap-northeast-1:294756904836:loadbalancer/app/libra-demo01/4b948d2735b469dd',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-0b4942a3396da7db8' }],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:ap-northeast-1:294756904836:loadbalancer/app/RedmineELB/e67a02825fc827a0',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-00799c8889c34ed13' }],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:ap-northeast-1:294756904836:loadbalancer/app/TTBJTest/1ab3aed379b8d020',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-02d0dfe8fe98f7397' }],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Platon1100/2480b750813a9f1d',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [],
    levels: [
      { downstreamResourceId: 'i-06fb61652188003c0' },
      { downstreamResourceId: 'i-07a9079dba410a52b' },
      { downstreamResourceId: 'i-0d723710f9902a8ee' },
    ],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/xe-cluster-alb-admin/f032e5c1c9404c09',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [],
    levels: [
      { downstreamResourceId: 'i-0102dd5fb86809c0e' },
      { downstreamResourceId: 'i-0594d9aa02b3dbf56' },
      { downstreamResourceId: 'i-07864c6db38454605' },
      { downstreamResourceId: 'i-0ccc5a8df6d7359de' },
    ],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Xe-Internal-alb-app01/688d505a12c6b110',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-Internal-alb-app01' }],
    levels: [
      { downstreamResourceId: 'i-02eaa01d7a3cb4276' },
      { downstreamResourceId: 'i-0d30ac429a3aeff3b' },
    ],
  },
  {
    resourceId:
      'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Xe-Internet-alb/3af85c5e15eb93dc',
    resourceType: 'alb',
    monitoredAt: '2020-08-17T07:51:03Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-Internet-alb' }],
    levels: [
      { downstreamResourceId: 'i-08ec0d1c46ea97a15' },
      { downstreamResourceId: 'i-0c87b082c406fcc66' },
    ],
  },
  {
    resourceId: 'arn:aws:rds:us-west-2:294756904836:db:libra-mysql',
    resourceType: 'rds',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [{ tagKey: 'workload-type', tagValue: 'その他' }],
    levels: [],
  },
  {
    resourceId:
      'arn:aws:rds:us-west-2:294756904836:db:nomad-cluster-db-instance',
    resourceType: 'rds',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'arn:aws:rds:us-west-2:294756904836:db:platon-test',
    resourceType: 'rds',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [],
    levels: [],
  },
  {
    resourceId:
      'crowi-sae-logosware-net-elb-36148640.us-west-2.elb.amazonaws.com',
    resourceType: 'clb',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [{ tagKey: 'Name', tagValue: 'crowi-sae-logosware-net-elb' }],
    levels: [{ downstreamResourceId: 'i-06c21b68eb4c07479' }],
  },
  {
    resourceId: 'DevSaaSELB-1929609090.us-west-2.elb.amazonaws.com',
    resourceType: 'clb',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [],
    levels: [{ downstreamResourceId: 'i-0dcd6e2b8303069c8' }],
  },
  {
    resourceId: 'i-00799c8889c34ed13',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SoumuRedmine' }],
    levels: [],
  },
  {
    resourceId: 'i-0102dd5fb86809c0e',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'group', tagValue: 'blue_cluster' },
      { tagKey: 'Name', tagValue: 'xe-cluster-client-instance_01' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-0121a083462cea01d',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Win2019Test' }],
    levels: [],
  },
  {
    resourceId: 'i-015ae0c0cd00ea731',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'TestActForGC_C5' }],
    levels: [],
  },
  {
    resourceId: 'i-016528e5a9c6ceb5e',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'MoodleTest' }],
    levels: [],
  },
  {
    resourceId: 'i-016fecf7f9f152c2a',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'ECCube' }],
    levels: [],
  },
  {
    resourceId: 'i-01d1807dee6636cfd',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'Name', tagValue: 'SecTestYanaka' },
      { tagKey: 'Product', tagValue: 'Otegaru' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-01d252576dac43eff',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCTestAdmin' }],
    levels: [],
  },
  {
    resourceId: 'i-01dd9f37ff30b1c6f',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'FLSDKBuild' }],
    levels: [],
  },
  {
    resourceId: 'i-022004a15d3e66753',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCCTest' }],
    levels: [],
  },
  {
    resourceId: 'i-025430f817fd3a9f3',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'IPTest' }],
    levels: [],
  },
  {
    resourceId: 'i-02d0dfe8fe98f7397',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'TTBJTest' }],
    levels: [],
  },
  {
    resourceId: 'i-02eaa01d7a3cb4276',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-App01-a-01' }],
    levels: [],
  },
  {
    resourceId: 'i-0392786db8b99d048',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCChat02' }],
    levels: [],
  },
  {
    resourceId: 'i-039a8f491637548a7',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'LibraTest' }],
    levels: [],
  },
  {
    resourceId: 'i-043057969581f7b98',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCBlazor' }],
    levels: [],
  },
  {
    resourceId: 'i-0489d95fed4aa5594',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'LibraTest20171024' }],
    levels: [],
  },
  {
    resourceId: 'i-04c33f17094bfd3c5',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'ChromiumBuild' }],
    levels: [],
  },
  {
    resourceId: 'i-04e578dbfdd75380e',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: '産総研Test' }],
    levels: [],
  },
  {
    resourceId: 'i-05111a25ce11deaea',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'xe-develop' }],
    levels: [],
  },
  {
    resourceId: 'i-05319a2e1cf8713d7',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'LibraDBTest' }],
    levels: [],
  },
  {
    resourceId: 'i-054beabab675df445',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SANagatsugi' }],
    levels: [],
  },
  {
    resourceId: 'i-0590e572ee33b5e5e',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SoumuRHELTest1' }],
    levels: [],
  },
  {
    resourceId: 'i-0594d9aa02b3dbf56',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'group', tagValue: 'blue_cluster' },
      { tagKey: 'Name', tagValue: 'xe-cluster-server-instance_01' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-0595b2e16aeecf31e',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'LibraDemo2' }],
    levels: [],
  },
  {
    resourceId: 'i-059eefb8206e3731f',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'BabayaTest' }],
    levels: [],
  },
  {
    resourceId: 'i-066767cfaa4d00032',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [],
    levels: [],
  },
  {
    resourceId: 'i-0692dc39359352b74',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SAEUSW2Mail' }],
    levels: [],
  },
  {
    resourceId: 'i-06a7159a916034545',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SecTest' }],
    levels: [],
  },
  {
    resourceId: 'i-06c21b68eb4c07479',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'Backup-Generation', tagValue: '7' },
      { tagKey: 'Name', tagValue: 'crowi-sae-logosware-net-instance-01' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-06fa54d1dd6d9eaf8',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCChat01' }],
    levels: [],
  },
  {
    resourceId: 'i-06fb61652188003c0',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Platon110PlatanusSub' }],
    levels: [],
  },
  {
    resourceId: 'i-070b9a7ee4d6167a5',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'PlatonTestSub' }],
    levels: [],
  },
  {
    resourceId: 'i-07136aa30327db325',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'WinTest' }],
    levels: [],
  },
  {
    resourceId: 'i-076baa74631068f71',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'PlatonSAMLTest' }],
    levels: [],
  },
  {
    resourceId: 'i-0785fa831fb9ae30f',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCTemplate' }],
    levels: [],
  },
  {
    resourceId: 'i-07864c6db38454605',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'group', tagValue: 'blue_cluster' },
      { tagKey: 'Name', tagValue: 'xe-cluster-server-instance_02' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-07a9079dba410a52b',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Platon110Platanus' }],
    levels: [],
  },
  {
    resourceId: 'i-07b55873b814543f1',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCTestTrans' }],
    levels: [],
  },
  {
    resourceId: 'i-07c086c8e4a19e82a',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'PlatonTest' }],
    levels: [],
  },
  {
    resourceId: 'i-07f1e9f2b6e5373f5',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SoumuZabbix' }],
    levels: [],
  },
  {
    resourceId: 'i-0847558cfb2b46bf3',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'WebFL' }],
    levels: [],
  },
  {
    resourceId: 'i-088eef3d318bcb61a',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'TestActForGC' }],
    levels: [],
  },
  {
    resourceId: 'i-08b9459f2c0ca879e',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SATest' }],
    levels: [],
  },
  {
    resourceId: 'i-08ec0d1c46ea97a15',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-Gateway-a-01' }],
    levels: [],
  },
  {
    resourceId: 'i-08fc0bdb6f9c768b7',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'InterWaveTest' }],
    levels: [],
  },
  {
    resourceId: 'i-08fc4e36f9288eab4',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SoumuRHELTest2' }],
    levels: [],
  },
  {
    resourceId: 'i-0906c07328e31e0c9',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'LibraInstallTest' }],
    levels: [],
  },
  {
    resourceId: 'i-099ba3c47cba32ae1',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'RHELTest' }],
    levels: [],
  },
  {
    resourceId: 'i-09fdb7be35bc31e1f',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Zabbix5Test' }],
    levels: [],
  },
  {
    resourceId: 'i-0a42b882e634fc052',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCTest' }],
    levels: [],
  },
  {
    resourceId: 'i-0aeca10c87c66dbb2',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SAInstallTest' }],
    levels: [],
  },
  {
    resourceId: 'i-0b3d32d7546e71342',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'NRITest' }],
    levels: [],
  },
  {
    resourceId: 'i-0b4942a3396da7db8',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Libra256Test' }],
    levels: [],
  },
  {
    resourceId: 'i-0b722b5066547f6a6',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'APITest' }],
    levels: [],
  },
  {
    resourceId: 'i-0ba807b8b743a6e85',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCTest20171215' }],
    levels: [],
  },
  {
    resourceId: 'i-0c099e788806788fd',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SATest2' }],
    levels: [],
  },
  {
    resourceId: 'i-0c3e50181234332a5',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'ChromiumBuild2' }],
    levels: [],
  },
  {
    resourceId: 'i-0c47cf8aeb6e597ac',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'GCC Performance Test2' }],
    levels: [],
  },
  {
    resourceId: 'i-0c5b259e66f7642b8',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'DNPPlaton' }],
    levels: [],
  },
  {
    resourceId: 'i-0c6debbd6ba4f2249',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'KEK' }],
    levels: [],
  },
  {
    resourceId: 'i-0c87b082c406fcc66',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-Gateway-b-01' }],
    levels: [],
  },
  {
    resourceId: 'i-0ccc5a8df6d7359de',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'group', tagValue: 'blue_cluster' },
      { tagKey: 'Name', tagValue: 'xe-cluster-server-instance_03' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-0d30ac429a3aeff3b',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Xe-App01-b-01' }],
    levels: [],
  },
  {
    resourceId: 'i-0d4cbba135cb8a0ad',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [
      { tagKey: 'group', tagValue: 'base_controller' },
      { tagKey: 'Name', tagValue: 'xe-cluster-base-instance-01' },
    ],
    levels: [],
  },
  {
    resourceId: 'i-0d723710f9902a8ee',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Platon110' }],
    levels: [],
  },
  {
    resourceId: 'i-0d93973bc01da78c9',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'ShimadzuTest' }],
    levels: [],
  },
  {
    resourceId: 'i-0dcd6e2b8303069c8',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'YanakaSAETool_OnService0404' }],
    levels: [],
  },
  {
    resourceId: 'i-0e2343ba6c005363a',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'Libra-Test2.2.0' }],
    levels: [],
  },
  {
    resourceId: 'i-0e9d37eacc55a48d6',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'WasedaMariaTest' }],
    levels: [],
  },
  {
    resourceId: 'i-0ebf91ee2b12d2992',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'SSLTemp20180529' }],
    levels: [],
  },
  {
    resourceId: 'i-0f0cbc8bc1595417a',
    resourceType: 'ec2',
    monitoredAt: '2020-08-17T07:51:09Z',
    tags: [{ tagKey: 'Name', tagValue: 'KracieTest' }],
    levels: [],
  },
  {
    resourceId: 'LibraELB-184677599.us-west-2.elb.amazonaws.com',
    resourceType: 'clb',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [{ tagKey: 'Name', tagValue: 'LibraELB' }],
    levels: [{ downstreamResourceId: 'i-039a8f491637548a7' }],
  },
  {
    resourceId: 'PlatonELB-1910390646.us-west-2.elb.amazonaws.com',
    resourceType: 'clb',
    monitoredAt: '2020-08-17T07:51:01Z',
    tags: [{ tagKey: 'Name', tagValue: 'PlatonELB' }],
    levels: [
      { downstreamResourceId: 'i-070b9a7ee4d6167a5' },
      { downstreamResourceId: 'i-07c086c8e4a19e82a' },
    ],
  },
];
