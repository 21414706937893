export type SelfSetting = {
  passwordLogin: boolean;
  gcSeminarMonitor: boolean;
  gcInfraMonitor: boolean;
  accessLog: boolean;
  account: boolean;
  actionLog: boolean;
  ami: boolean;
  dashboard: boolean;
  domain: boolean;
  errorLog: boolean;
  inframonitor: boolean;
  infratree: boolean;
  key: boolean;
  newsStack: boolean;
  postData: boolean;
};

export const defaultSelfSetting: SelfSetting = {
  passwordLogin: false,
  gcSeminarMonitor: true,
  gcInfraMonitor: true,
  accessLog: true,
  account: false,
  actionLog: true,
  ami: false,
  dashboard: false,
  domain: false,
  errorLog: true,
  inframonitor: false,
  infratree: false,
  key: false,
  newsStack: false,
  postData: true,
};
