import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  IconButton,
  Modal,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AccountForm } from '../../domain/Userbase/Account';
import * as api from '../../api/userbase';
import { Success, useDispatch } from '../../store/provider';

type Props = {
  /**
   * 編集対象となるアカウントデータ
   */
  data: AccountForm;

  /**
   * 一覧更新処理へのインターフェース
   */
  reloadList: () => void;

  changeEdit: (e: React.ChangeEvent<HTMLInputElement>) => void;

  closeEdit: () => void;
};

export const Edit: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await api.accountUpdate(props.data);
    Success(dispatch, 'updated');
    props.reloadList();
    props.closeEdit();
  };

  const handleDelete = async (e: React.MouseEvent) => {
    if (!window.confirm('ok?')) {
      return;
    }
    await api.accountDelete(props.data.accountId);
    Success(dispatch, 'deleted');
    props.reloadList();
    props.closeEdit();
  };

  let form = <div />;
  if (props.data.accountId > 0) {
    form = (
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.paper}>
          <CardHeader
            style={{ background: '#eee' }}
            action={
              <div aria-label="settings">
                <IconButton onClick={props.closeEdit}>
                  <CloseIcon />
                </IconButton>
              </div>
            }
          />
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              name="loginId"
              label="LoginId"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.loginId}
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.email}
            />
            <TextField
              name="familyName"
              label="Familyname"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.familyName}
            />
            <TextField
              name="givenName"
              label="GivenName"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.givenName}
            />
            <TextField
              name="beginDate"
              label="BeginDate"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.beginDate}
            />
            <TextField
              name="endDate"
              label="EndDate"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.endDate}
            />
            <CardActions>
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>
    );
  }

  return form;
};

const dialogWidth = 600;
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: dialogWidth,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      top: '10%',
      left: 'calc(50% - 300px)',
    },
    form: {
      padding: theme.spacing(2, 4, 3),
    },
  })
);
