import * as React from "react";
import { Color } from "@material-ui/lab/Alert";
import { Action, initialState, reducer, State } from "./reducer";

const { createContext, useContext, useReducer } = React;

const stateContext = createContext(initialState);
const dispatchContext = createContext((() => true) as React.Dispatch<Action>);

export const Provider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <dispatchContext.Provider value={dispatch}>
      <stateContext.Provider value={state}>
        {props.children}
      </stateContext.Provider>
    </dispatchContext.Provider>
  );
};

export const useDispatch = () => {
  return useContext(dispatchContext);
};

export const useGlobalState = <K extends keyof State>(property: K) => {
  const state = useContext(stateContext);
  return state[property];
};

const messageAlert = (
  dispatch: React.Dispatch<Action>,
  severity: Color,
  message: string
) => {
  dispatch({
    type: "add_message",
    message: { severity: severity, text: message },
  });
  setTimeout(() => {
    dispatch({
      type: "remove_message",
    });
  }, 5000);
};

export const Success = (dispatch: React.Dispatch<Action>, message: string) => {
  messageAlert(dispatch, "success", message);
};

export const Info = (dispatch: React.Dispatch<Action>, message: string) => {
  messageAlert(dispatch, "info", message);
};

export const Warning = (dispatch: React.Dispatch<Action>, message: string) => {
  messageAlert(dispatch, "warning", message);
};

export const Error = (dispatch: React.Dispatch<Action>, message: string) => {
  messageAlert(dispatch, "error", message);
};
