import React from 'react';

/**
 * 表示するコンテンツがないことを表すコンポーネント。
 * このモジュールに存在しない(他のモジュールにあるはずの)URLを指定された場合などに使う。
 * ネットワークルーティングを間違えたり、開発中で他のモジュールへのルーティング無い場合にだけ使う想定。
 */
export const NoContents: React.FC = () => {
  return <>no contents</>;
};
