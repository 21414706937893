import React from 'react';
import { AccessLog } from '../../domain/AccessLog';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * 詳細情報データ
   */
  data: AccessLog;

  /**
   * 詳細情報を非表示にするためのインターフェース
   */
  closeDetail: () => void;
};

export const Detail: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const d = props.data;

  if (d.requestId === '') {
    return <div></div>;
  }

  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <IconButton aria-label="settings" onClick={props.closeDetail}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List>
        <ListItem>
          <div className={classes.attrLabel}>RequestID</div>
          <div>{d.requestId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Datetime</div>
          <div>{DateFormat(d.datetime)}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>ClientIP</div>
          <div>{d.clientIp}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Method</div>
          <div>{d.method}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>URI</div>
          <div className={classes.attrValue}>
            {d.scheme + '://' + d.host + d.path + d.query}
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Status</div>
          <div>{d.status}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>AccountID</div>
          <div>{d.accountId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>LoginID</div>
          <div>{d.loginId}</div>
        </ListItem>
      </List>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: '720px',
    width: '30%',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
