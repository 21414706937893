import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  IconButton,
  Modal,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as api from '../../api/newsstack';
import { Success, useDispatch } from '../../store/provider';
import { defaultNewsForm } from '../../domain/NewsStack';

type Props = {
  reloadList: () => void;
};

export const New: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [apper, setApper] = useState(false);
  const [newNews, setNews] = useState(defaultNewsForm);
  const dispatch = useDispatch();

  const handleNewAppear = () => {
    setApper(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setNews((prevState) => {
      switch (e.target.name) {
        case 'context':
          return { ...prevState, context: e.target.value };
        case 'message':
          return { ...prevState, message: e.target.value };
        case 'linkUrl':
          return { ...prevState, linkUrl: e.target.value };
        default:
          return prevState;
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await api.addNews(newNews);
    Success(dispatch, 'created');
    setApper(false);
    props.reloadList();
  };

  const handleClose = () => {
    setApper(false);
  };

  let form = <div />;
  if (apper) {
    form = (
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.paper}>
          <CardHeader
            style={{ background: '#eee' }}
            action={
              <div aria-label="settings">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            }
          />
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              name="context"
              label="Context"
              fullWidth
              onChange={handleChange}
            />
            <TextField
              name="message"
              label="Message"
              fullWidth
              onChange={handleChange}
            />
            <TextField
              name="linkUrl"
              label="LinkURL"
              fullWidth
              onChange={handleChange}
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </form>
        </Card>
      </Modal>
    );
  }

  return (
    <div>
      <Button variant="contained" onClick={handleNewAppear}>
        New
      </Button>
      {form}
    </div>
  );
};

const dialogWidth = 600;
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: dialogWidth,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      top: '10%',
      left: 'calc(50% - 300px)',
    },
    form: {
      padding: theme.spacing(2, 4, 3),
    },
  })
);
