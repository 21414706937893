import dayjs from 'dayjs';
import { ResourceType } from './InfraResource';

export type SystemLog = {
  id: string;
  datetime: string;
  resourceId: string;
  resourceType: ResourceType;
  tag: string;
  record: string;
};
export const defaultSystemLog: SystemLog = {
  id: '',
  datetime: '',
  resourceId: '',
  resourceType: '',
  tag: '',
  record: '',
};

export type SearchCondition = {
  datetimeStart: String;
  datetimeEnd: string;
  resourceId: string;
  resourceType: string;
  tag: string;
  limit: number;
};

export const defaultSearchCondition: SearchCondition = {
  datetimeStart: dayjs().toISOString(),
  datetimeEnd: dayjs().toISOString(),
  resourceId: '',
  resourceType: '',
  tag: '',
  limit: 0,
};

/**
 * システムログの詳細検索フィールド
 */
export const advancedSearchFields = [];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string
): SearchCondition => {
  switch (key) {
    case 'datetimeStart':
      return { ...cond, datetimeStart: value };
    case 'datetimeEnd':
      return { ...cond, datetimeEnd: value };
    case 'resourceId':
      return { ...cond, resourceId: value };
    case 'resourceType':
      return { ...cond, resourceType: value };
    case 'tag':
      return { ...cond, tag: value };
    case 'limit':
      return { ...cond, limit: parseInt(value) };
  }
};
