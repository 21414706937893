import { post } from './io';
import {
  AccessLogList,
  AccessLogDescribe,
  SearchCondition,
} from '../domain/AccessLog';
import dayjs from 'dayjs';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const list = async (cond: SearchCondition): Promise<AccessLogList> => {
  const url = rootUrl + '/base_api/accesslog/lw/list';
  const params = {
    ...cond,
    datetimeEnd: dayjs(cond.datetimeEnd).add(1, 'day').toISOString(),
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const describe = async (
  requestId: string
): Promise<AccessLogDescribe> => {
  const url = rootUrl + '/base_api/accesslog/lw/describe';
  const params = {
    requestId,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};
