import React from 'react';
import { PostData } from '../../domain/PostData';
import {
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * Postログの一覧表示データ
   */
  data: PostData[];

  /**
   * 詳細情報を表示するためのインターフェース
   */
  openDetail: (index: number) => void;
};

export const List: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">RequestId</TableCell>
              <TableCell align="right">Method</TableCell>
              <TableCell align="right">URI</TableCell>
              <TableCell align="right">Datetime</TableCell>
              <TableCell align="right">Body</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((d, i) => (
              <TableRow
                key={d.requestId}
                hover
                onClick={() => props.openDetail(i)}
              >
                <TableCell component="th" scope="row">
                  {d.requestId}
                </TableCell>
                <TableCell align="right">
                  <Chip label={d.method} />
                </TableCell>
                <TableCell align="right" className={classes.uriCell}>
                  {d.scheme + '://' + d.host + d.path + d.query}
                </TableCell>
                <TableCell align="right">
                  {' '}
                  {DateFormat(d.requestDatetime)}
                </TableCell>
                <TableCell align="right">{d.requestBody}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => props.openDetail(i)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  uriCell: {
    wordBreak: 'break-all',
  },
});
