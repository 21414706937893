export type RSAKey = {
  keyId: number;
  keyName: string;
  domainId: number;
  publickey: string;
  disabled: boolean;
};

export const defaultKey: RSAKey = {
  keyId: 0,
  keyName: '',
  domainId: 0,
  publickey: '',
  disabled: false,
};
