import React, { useState, useEffect, useCallback } from 'react';
import * as api from '../api/newsstack';
import {
  News,
  defaultNews,
  NewsForm,
  defaultNewsForm,
  defaultSearchCondition,
} from '../domain/NewsStack';
import { dateFormat } from '../domain/dateFormat';
import { Detail } from '../components/NewsStack/Detail';
import { List } from '../components/NewsStack/List';
import { New } from '../components/NewsStack/New';
import { Edit } from '../components/NewsStack/Edit';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

export const NewsStackControl: React.FC = () => {
  const classes = useStyles();
  const [list, setList] = useState([] as News[]);
  const [detail, setDetail] = useState(defaultNews);
  const [edit, setEdit] = useState(defaultNewsForm);
  const history = useHistory();

  /**
   * 検索を実行する
   */
  const executeSearch = useCallback(async () => {
    const data = (await api.listNews(defaultSearchCondition)) || [];
    setList(data);
  }, []);

  /**
   *　詳細情報を表示する
   */
  const openDetail = useCallback(
    async (notificationId: number) => {
      history.push('/base/newsstack/detail/' + notificationId.toString());
      const detail = await api.describeNews(notificationId);
      setDetail(detail);
    },
    [history]
  );

  /**
   * 詳細情報を非表示にする
   */
  const closeDetail = (): void => {
    history.push('/base/newsstack/');
    setDetail(defaultNews);
  };

  /**
   * 編集ダイアログを表示する
   */
  const openEdit = (): void => {
    const form = {
      ...detail,
      notifiedAt: dateFormat(detail.notifiedAt),
    } as NewsForm;
    setEdit(form);
  };

  const closeEdit = () => {
    setEdit(defaultNewsForm);
  };

  const changeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setEdit((prevState: NewsForm) => {
      switch (e.target.name) {
        case 'message':
          return { ...prevState, message: e.target.value };
        case 'linkUrl':
          return { ...prevState, linkUrl: e.target.value };
        case 'notifiedAt':
          return { ...prevState, notifiedAt: e.target.value };

        default:
          return prevState;
      }
    });
  };

  /**
   * 初期状態の取得など
   */
  useEffect(() => {
    executeSearch();

    const paths = document.location.pathname.split('/');
    if (paths.length > 4) {
      if (paths[3] === 'detail') {
        openDetail(parseInt(paths[4]));
      }
    }
  }, [executeSearch, openDetail]);

  return (
    <div className={classes.container}>
      <New reloadList={executeSearch} />
      <Edit
        data={edit}
        reloadList={async () => {
          await executeSearch();
          await openDetail(detail.notificationId);
        }}
        changeEdit={changeEdit}
        closeEdit={closeEdit}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="h4" component="h2">
            NewsStack
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <List data={list} openDetail={openDetail} />
        </Grid>
      </Grid>
      <Detail data={detail} closeDetail={closeDetail} openEdit={openEdit} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
