import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {
  passwordLoginChallenge,
  passwordLogin,
  googleLogin,
} from '../api/userbase';
import { generateLoginHash } from '../domain/Auth';
import Session from '../store/session';
import { Success, Error, useDispatch } from '../store/provider';

interface LoginInfo {
  loginId: string;
  password: string;
}

const Login: React.FC = () => {
  const classes = useStyles();
  const [loginInfo, setLoginInfo] = useState({
    loginId: '',
    password: '',
  } as LoginInfo);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setLoginInfo((prevState) => {
      switch (e.target.id) {
        case 'loginId':
          return { ...prevState, loginId: e.target.value };
        case 'password':
          return { ...prevState, password: e.target.value };
        default:
          return prevState;
      }
    });
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const challenge = await passwordLoginChallenge(loginInfo.loginId);

      const loginHash = generateLoginHash(
        loginInfo.password,
        challenge.challenge
      );
      const res = await passwordLogin(loginInfo.loginId, loginHash);

      if (res.token === '') return;

      Session.setLogin();
      Success(dispatch, 'ログインしました');

      history.push('/base/dashboard');
    } catch {
      Error(dispatch, 'ログインに失敗しました');
      Error(dispatch, 'IDとパスワードをご確認ください');
    }
  };

  const handleGoogleSignin = async () => {
    try {
      const redirect = await googleLogin();
      window.location.href = redirect;
    } catch {
      Error(dispatch, 'ログインに失敗しました');
      Error(
        dispatch,
        '再度試しても失敗する場合、システム障害の可能性があります。管理者に連絡してください'
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h4">
          {process.env.REACT_APP_SITE_TITLE}
        </Typography>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h2" variant="h5">
          Sign in
        </Typography>
        {
          // パスワードログインがONのときはフォームを表示
          process.env.REACT_APP_PASSWORD_LOGIN_ON === 'true' && (
            <form className={classes.form} noValidate onSubmit={handleLogin}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="loginId"
                label="LoginID"
                type="text"
                value={loginInfo.loginId}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={loginInfo.password}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )
        }

        <Button
          type="button"
          className={classes.submit}
          onClick={handleGoogleSignin}
        >
          <img
            src="/btn_google_signin_light_normal_web.png"
            alt="Signin with Google"
          />
        </Button>
      </div>
    </Container>
  );
};

export default withRouter(Login);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
