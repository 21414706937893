export type News = {
  notificationId: number;
  context: string;
  message: string;
  linkUrl: string;
  notifiedAt: string;
  accountIds: number[];
};

export const defaultNews: News = {
  notificationId: 0,
  context: '',
  message: '',
  linkUrl: '',
  notifiedAt: '',
  accountIds: [],
};

export type NewsForm = {
  notificationId: number;
  context: string;
  message: string;
  linkUrl: string;
  notifiedAt: string;
  accountIds: number[];
};

export const defaultNewsForm: NewsForm = {
  notificationId: 0,
  context: '',
  message: '',
  linkUrl: '',
  notifiedAt: '',
  accountIds: [],
};

export type SearchCondition = {
  context: string;
  notifiedAtStart: string;
  notifiedAtEnd: string;
};

export const defaultSearchCondition: SearchCondition = {
  context: '',
  notifiedAtStart: '',
  notifiedAtEnd: '',
};
