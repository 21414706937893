import { Datetime, newDate } from 'domain/dateFormat';

export type ActionLog = {
  id: number;
  product: string;
  function: string;
  requestId: string;
  url: string;
  userId: number;
  userName: string;
  target: string;
  description: string;
  occurredAt: string;
  customerDomain: string;
  customerName: string;
};

export const defaultActionLog: ActionLog = {
  id: 0,
  product: '',
  function: '',
  requestId: '',
  url: '',
  userId: 0,
  userName: '',
  target: '',
  description: '',
  occurredAt: '',
  customerDomain: '',
  customerName: '',
};

export type SearchCondition = {
  match: string;
  product: string;
  customerDomain: string;
  userId: number;
  target: string;
  occurredAtStart: string;
  occurredAtEnd: string;
  limit: number;
  offset: number;
};

export const defaultSearchCondition: SearchCondition = {
  match: '',
  product: '',
  customerDomain: '',
  userId: 0,
  target: '',
  occurredAtStart: newDate().toISOString(),
  occurredAtEnd: newDate().toISOString(),
  limit: 0,
  offset: 0,
};

/**
 * Postログの詳細検索フィールド
 */
export const advancedSearchFields = [
  {
    input: 'TextField',
    id: 'product',
    label: 'Product',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'customerDomain',
    label: 'CustomerDomain',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'requestId',
    label: 'RequestID',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'userId',
    label: 'UseriD',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'target',
    label: 'Target',
    type: 'text',
  },
];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string | number | Datetime
): SearchCondition => {
  switch (key) {
    case 'match':
      return { ...cond, match: value as string };
    case 'product':
      return { ...cond, product: value as string };
    case 'customerDomain':
      return { ...cond, customerDomain: value as string };
    case 'userId':
      return { ...cond, userId: value as number };
    case 'target':
      return { ...cond, target: value as string };
    case 'occurredAtStart':
      return { ...cond, occurredAtStart: (value as Datetime).toISOString() };
    case 'occurredAtEnd':
      return { ...cond, occurredAtEnd: (value as Datetime).toISOString() };
    case 'limit':
      return { ...cond, limit: value as number };
    case 'offset':
      return { ...cond, offset: value as number };
  }
};
