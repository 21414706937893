import React from 'react';
import { News } from '../../domain/NewsStack';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconEdit from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  /**
   * 詳細情報データ
   */
  data: News;

  /**
   * 詳細情報を非表示にするためのインターフェース
   */
  closeDetail: () => void;

  /**
   * データ編集ダイアログを表示にするためのインターフェース
   */
  openEdit: () => void;
};

export const Detail: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const d = props.data;

  if (d.notificationId === 0) {
    return <div />;
  }

  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <div aria-label="settings">
            <IconButton onClick={props.openEdit}>
              <IconEdit />
            </IconButton>
            <IconButton onClick={props.closeDetail}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <List>
        <ListItem>
          <div className={classes.attrLabel}>NotificationId</div>
          <div className={classes.attrValue}>{d.notificationId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Context</div>
          <div className={classes.attrValue}>{d.context}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Message</div>
          <div className={classes.attrValue}>{d.message}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>linkUrl</div>
          <div className={classes.attrValue}>{d.linkUrl}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>NotifiedAt</div>
          <div className={classes.attrValue}>{d.notifiedAt}</div>
        </ListItem>
      </List>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: '720px',
    width: '30%',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
