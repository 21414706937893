import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * アプリケーション内でグローバルな、Datetime型
 * dayjsを辞めた時でも、この型を変えれば別のライブラリにできる(たぶん)。
 */
export type Datetime = dayjs.Dayjs;

export const newDate = (date?: string): Datetime => {
  return dayjs(date).hour(0).minute(0).second(0).millisecond(0);
};

export const newDatetime = (datetime?: string): Datetime => {
  return dayjs(datetime);
};

export const dateUtcFormat = (isoDate: string): string | null => {
  if (isoDate === '') {
    return null;
  }
  return isoDate + 'T00:00:00.000Z';
};

export const dateTimeUtcFormat = (isoDate: string): string | null => {
  if (isoDate === '') {
    return null;
  }
  return dayjs(isoDate).utc().format();
};

export const dateFormat = (isoDate: string | null): string => {
  if (isoDate === null || isoDate === '') {
    return '';
  }
  return dayjs(isoDate).format('YYYY-MM-DD');
};
