import React from 'react';
import { Resource } from '../../domain/InfraResource';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * 詳細情報データ
   */
  data: Resource;

  /**
   * 詳細情報を非表示にするためのインターフェース
   */
  closeDetail: () => void;
};

export const Detail: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const d = props.data;

  if (d.resourceId === '') {
    return <div />;
  }

  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <div aria-label="settings">
            <IconButton aria-label="settings" onClick={props.closeDetail}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <List>
        <ListItem>
          <div className={classes.attrLabel}>ID</div>
          <div>{d.resourceId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>AMIName</div>
          <div>{d.resourceType}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>CreatedAt</div>
          <div>{DateFormat(d.monitoredAt)}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Tags</div>
          <ul>
            {d.tags.map((t) => {
              return (
                <li key={t.tagKey}>
                  {t.tagKey}: {t.tagValue}
                </li>
              );
            })}
          </ul>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Levels</div>
          <ul>
            {d.levels.map((level) => {
              return (
                <li key={level.downstreamResourceId}>
                  {level.downstreamResourceId}
                </li>
              );
            })}
          </ul>
        </ListItem>
      </List>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: '720px',
    width: '30%',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
