import React from 'react';
import { AMI } from '../../domain/AMI';
import { RunCondition } from '../../domain/InfraCommand';
import * as commandApi from '../../api/infracommand';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';
import { Success, useDispatch } from '../../store/provider';

type Props = {
  /**
   * 詳細情報データ
   */
  data: AMI;

  /**
   * 詳細情報を非表示にするためのインターフェース
   */
  closeDetail: () => void;
};

export const Detail: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const d = props.data;

  const handleLaunchMachine = async (amiId: string) => {
    if (!window.confirm('launch ami')) {
      return;
    }
    await commandApi.run({
      resion: 'us-west-2',
      imageId: amiId,
      instanceType: 't3a.nano',
    } as RunCondition);
    Success(dispatch, 'run');
  };

  if (d.amiId === '') {
    return <div />;
  }

  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <div aria-label="settings">
            <IconButton
              onClick={() => {
                handleLaunchMachine(props.data.amiId);
              }}
            >
              <PlayCircleFilledIcon />
            </IconButton>
            <IconButton onClick={props.closeDetail}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <List>
        <ListItem>
          <div className={classes.attrLabel}>ID</div>
          <div>{d.amiId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>AMIName</div>
          <div>{d.amiName}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>CreatedAt</div>
          <div>{DateFormat(d.amiCreatedAt)}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>MonitoredAt</div>
          <div>{d.monitoredAt}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Tags</div>
          <ul>
            {d.tags.map((t) => {
              return (
                <li key={t.tagKey}>
                  {t.tagKey}: {t.tagValue}
                </li>
              );
            })}
          </ul>
        </ListItem>
      </List>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: '720px',
    width: '30%',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
