import {sha512} from 'js-sha512';

export const generateLoginHash = (password: string, challenge: string): string => {
  const passHash = passwordHash(password);
  return loginHash(passHash, challenge);
}

const passwordHash = (raw: string):string => {
  const one = sha512(raw);
 return sha512(one);
}

const loginHash = (passHash: string, challenge: string): string => {
  const one = sha512(passHash + challenge);
  return sha512(one);
}