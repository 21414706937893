import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import Session from "../store/session";

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.headers.common["X-CSRF-TOKEN"] = "default";

const errorHandler = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    Session.unsetLogin();
    window.location.href = "/login";
    return;
  }
  throw error;
};

export const post = async (
  url: string,
  params?: any,
  config?: AxiosRequestConfig | undefined
): Promise<any> => {
  let ret = null;
  await axios
    .post(url, params, config)
    .then((items: AxiosResponse<any>) => {
      if (items.data.errors.length > 0) {
        window.alert("error occured");
        return;
      }
      ret = items.data.body;
    })
    .catch(errorHandler);
  return Promise.resolve(ret);
};

export const get = async (
  url: string,
  config?: AxiosRequestConfig | undefined
): Promise<any> => {
  let ret = null;

  await axios
    .get(url, config)
    .then((items: AxiosResponse<any>) => {
      if (items.data.errors.length > 0) {
        window.alert("error occured");
        return;
      }
      ret = items.data.body;
    })
    .catch(errorHandler);
  return Promise.resolve(ret);
};

/**
 * ログイン時のポストでは、401が返ってきても/loginに再遷移させない。
 * つまり、エラーハンドリングが通常と異なっているので、別の関数として用意している。
 * @param url
 * @param params
 * @param config
 */
export const loginPost = async (
  url: string,
  params?: any,
  config?: AxiosRequestConfig | undefined
): Promise<any> => {
  let ret = null;
  await axios.post(url, params, config).then((items: AxiosResponse<any>) => {
    if (items.data.errors.length > 0) {
      window.alert("error occured");
      return;
    }
    ret = items.data.body;
  });
  return Promise.resolve(ret);
};

/**
 * postメソッドで、バイナリファイルを取得
 * @param url
 * @param params
 * @param config
 */
export const binary = async (
  url: string,
  params?: any,
  config?: AxiosRequestConfig | undefined
): Promise<any> => {
  await axios
    .post(url, params, config)
    .then((items: AxiosResponse) => {
      if ((items as AxiosResponse).data.result === undefined) {
        const filename = dispositionFileName(
          items.headers["content-disposition"]
        );
        blobSave(items.data, filename);
      }
    })
    .catch(errorHandler);
  return Promise.resolve(true);
};

/**
 * getメソッドで、バイナリファイルを取得
 * @param url
 * @param params
 */
export const binaryGet = async (
  url: string,
  params?: any,
  config?: AxiosRequestConfig | undefined
): Promise<any> => {
  await axios
    .get(url, params)
    .then((items: AxiosResponse) => {
      if ((items as AxiosResponse).data.result === undefined) {
        const filename = dispositionFileName(
          items.headers["content-disposition"]
        );
        blobSave(items.data, filename);
      }
    })
    .catch(errorHandler);
  return Promise.resolve(true);
};

/**
 * blobSave Blobデータをローカルに保存する
 */
export function blobSave(blob: Blob, filename: string) {
  if (window.navigator.msSaveBlob) {
    // IE & Edge対応
    blob = new Blob([blob], {
      type: blob.type,
    });
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    const blobUrl = window.URL.createObjectURL(
      new Blob([blob], {
        type: blob.type,
      })
    );
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = blobUrl;
    a.download = filename;
    a.click();
  }
}

/**
 * dispositionFileName レスポンスヘッダーからファイル名を取得
 */
export function dispositionFileName(contentDisposition: string | null): string {
  // https://stackoverflow.com/questions/40939380/how-to-get-file-name-from-content-disposition/40940790
  if (contentDisposition === null) {
    return "unknown";
  }
  const filenameRegex = /filename[^;=\n]*=(UTF-8|utf-8)''((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[2]) {
    const encoded = matches[2].replace(/['"]/g, "");
    return decodeURIComponent(encoded);
  }
  return "unknown";
}
