import React from 'react';
import { SystemLog } from '../../domain/SystemLog';
import {
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * システムログデータ
   */
  list: SystemLog[];

  /**
   * システムログを非表示にするためのインターフェース
   */
  closeSystemLog: () => void;
};

export const SystemLogMonitor: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  if (props.list.length === 0) return <div />;
  return (
    <Card className={classes.container}>
      <CardHeader
        action={
          <div aria-label="settings">
            <IconButton aria-label="settings" onClick={props.closeSystemLog}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datetime</TableCell>
              <TableCell>Tag</TableCell>
              <TableCell>Record</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map((l, i) => (
              <TableRow key={l.resourceId}>
                <TableCell>{DateFormat(l.datetime)}</TableCell>
                <TableCell>{l.tag}</TableCell>
                <TableCell>{l.record}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    width: 'calc(100vw - 200px)',
    position: 'fixed',
    bottom: 0,
    height: '50%',
    overflowY: 'auto',
  },
});
