import React, { useCallback, useEffect } from 'react';
import { googleLoginCallback } from '../api/userbase';
import Session from '../store/session';
import { Success, Error, useDispatch } from '../store/provider';

export const GoogleSigninCallback: React.FC = () => {
  const dispatch = useDispatch();

  const login = useCallback(async () => {
    const params = window.location.search;

    try {
      const res = await googleLoginCallback(params);
      if (res.token === '') return;

      Session.setLogin();
      Success(dispatch, 'ログインしました');

      window.location.href = '/gcmon/seminars';
    } catch {
      Error(dispatch, 'ログインに失敗しました');
      return;
    }
  }, [dispatch]);

  useEffect(() => {
    login();
  }, [login]);

  return <div />;
};
