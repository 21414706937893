import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import * as selfSettingApi from './api/selfsetting';
import { defaultSelfSetting } from './domain/SelfSetting';
import Login from './views/Login';
import Logout from './views/Logout';
import { GoogleSigninCallback } from './components/GoogleSigninCallback';
import { NoContents } from 'components/NoContents';
import { TopRedirect } from 'components/TopRedirect';
import { Navi } from './Navi';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Auth from './Auth';
import theme from './styles/theme';
import { Provider } from './store/provider';
import { MessageArea } from './components/MessageArea';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

function App() {
  const classes = useStyles();
  const [selfSetting, setSelfSetting] = useState(defaultSelfSetting);

  const executeSearch = useCallback(async () => {
    const data = await selfSettingApi.selfSetting();
    setSelfSetting(data);
  }, []);

  useEffect(() => {
    executeSearch();
  }, [executeSearch]);

  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <MessageArea />
          <BrowserRouter>
            <Switch>
              <Route path="/login/google-signin-callback">
                <GoogleSigninCallback />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
              <Route path="/gcmon/seminars">
                <NoContents />
              </Route>
              <Auth>
                <Switch>
                  <Route>
                    <Navi selfSetting={selfSetting} />
                  </Route>
                </Switch>
              </Auth>
              <Route path="/">
                <TopRedirect />
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
