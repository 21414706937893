import React from 'react';
import { Account } from '../../domain/Userbase/Account';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconEdit from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  /**
   * 詳細情報データ
   */
  data: Account;

  /**
   * 詳細情報を非表示にするためのインターフェース
   */
  closeDetail: () => void;

  /**
   * データ編集ダイアログを表示にするためのインターフェース
   */
  openEdit: () => void;
};

export const Detail: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const d = props.data;

  if (d.accountId === 0) {
    return <div></div>;
  }

  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <div aria-label="settings">
            <IconButton onClick={props.openEdit}>
              <IconEdit />
            </IconButton>
            <IconButton onClick={props.closeDetail}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <List>
        <ListItem>
          <div className={classes.attrLabel}>AccountID</div>
          <div className={classes.attrValue}>{d.accountId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>LoginID</div>
          <div className={classes.attrValue}>{d.loginId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Email</div>
          <div className={classes.attrValue}>{d.email}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>familyName</div>
          <div className={classes.attrValue}>{d.familyName}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>GivenName</div>
          <div className={classes.attrValue}>{d.givenName}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>BeginDate</div>
          <div className={classes.attrValue}>{d.beginDate}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>EndDate</div>
          <div className={classes.attrValue}>{d.endDate}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>ForceDisable</div>
          <div className={classes.attrValue}>{d.forceDisable.toString()}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Deleted</div>
          <div className={classes.attrValue}>{d.deleted.toString()}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>DenyDelete</div>
          <div className={classes.attrValue}>{d.denyDelete.toString()}</div>
        </ListItem>
      </List>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: '720px',
    width: '30%',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
