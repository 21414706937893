import { post } from './io';
import { SystemLog, SearchCondition } from '../domain/SystemLog';
import dayjs from 'dayjs';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const systemLogList = async (
  cond: SearchCondition
): Promise<Array<SystemLog>> => {
  const url = rootUrl + '/base_api/systemlog/list';
  const params = {
    ...cond,
    datetimeEnd: dayjs(cond.datetimeEnd).add(1, 'day').toISOString(),
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const systemLogDescribe = async (amiId: string): Promise<SystemLog> => {
  const url = rootUrl + '/base_api/systemlog/describe';
  const params = {
    amiId,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};
