import React, { useState, useEffect } from "react";
import * as api from "../api/userbase";
import { RSAKey, defaultKey } from "../domain/Userbase/Key";
import { Button } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "../components/Key/List";

export const KeyControl: React.FC = () => {
  const classes = useStyles();
  const [primary, setPrimaryKey] = useState(defaultKey);
  const [secondary, setSecondaryKey] = useState({
    keyId: 0,
    keyName: "",
    domainId: 0,
    publickey: "",
    disabled: false,
  } as RSAKey);

  const load = async () => {
    const p = await api.primaryKey();
    setPrimaryKey(p);
    const s = await api.secondaryKey();
    setSecondaryKey(s);
  };

  const handleGenerate = async () => {
    await api.generateKey();
    await load();
  };

  const handleRevert = async () => {
    await api.revertKey();
    await load();
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="h4" component="h2">
            KeyManager
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Button onClick={handleGenerate} variant="contained">
            Generate
          </Button>
          <Button onClick={handleRevert} variant="contained">
            Revert
          </Button>
        </Grid>
        <Grid item sm={12} lg={12}>
          <List primary={primary} secondary={secondary} />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: "pre-wrap",
  },
}));
