import React, { useState, useEffect, useCallback } from 'react';
import * as api from '../api/inframonitor';
import {
  Resource,
  defaultResource,
  SearchCondition,
  defaultSearchCondition,
} from '../domain/InfraResource';
import { List } from '../components/InfraResource/List';
import { Detail } from '../components/InfraResource/Detail';
import { Search } from '../components/InfraResource/Search';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

export const InfraControl: React.FC = () => {
  const classes = useStyles();
  const [list, setList] = useState([] as Resource[]);
  const [detail, setDetail] = useState(defaultResource);
  const [condition, setSearchCondtion] = useState(defaultSearchCondition);
  const history = useHistory();

  /**
   *検索を実行する
   */
  const executeSearch = useCallback(async () => {
    const data = (await api.resourceList(condition)) || [];
    setList(data);
  }, [condition]);

  /**
   * 詳細情報を表示する
   */
  const openDetail = useCallback(
    async (resourceId: string) => {
      history.push('/base/inframonitor/detail/' + resourceId);
      const detail = await api.resourceDescribe(resourceId);
      setDetail(detail);
    },
    [history]
  );

  /**
   * 詳細情報を非表示にする
   */
  const closeDetail = (): void => {
    history.push('/base/inframonitor/');
    setDetail(defaultResource);
  };

  /**
   * 検索条件を変更する
   * @param data 新しい検索条件
   */
  const changeSearchCondition = (data: SearchCondition) => {
    setSearchCondtion(data);
  };

  /**
   * 初期状態の取得など
   */
  useEffect(() => {
    executeSearch();

    const paths = document.location.pathname.split('/');
    if (paths.length > 4) {
      if (paths[3] === 'detail') {
        openDetail(paths[4]);
      }
    }
  }, [executeSearch, openDetail]);

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="h4" component="h2">
            InfraResourceMonitor
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Search updateSearchCondition={changeSearchCondition} />
          <List data={list} openDetail={openDetail} />
        </Grid>
        <Grid item sm={12} lg={4}>
          <Paper>
            <Detail data={detail} closeDetail={closeDetail} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
