import React from 'react';
import { RSAKey } from '../../domain/Userbase/Key';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

type Props = {
  /**
   * プライマリー鍵のデータ
   */
  primary: RSAKey;

  /**
   * セカンダリー鍵のデータ
   */
  secondary: RSAKey;
};

export const List: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">KeyId</TableCell>
              <TableCell align="right">KeyName</TableCell>
              <TableCell align="right">DomainId</TableCell>
              <TableCell>PublicKey</TableCell>
              <TableCell align="right">Disabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {props.primary.keyId}
              </TableCell>
              <TableCell align="right">{props.primary.keyName}</TableCell>
              <TableCell align="right">{props.primary.domainId}</TableCell>
              <TableCell>{props.primary.publickey}</TableCell>
              <TableCell align="right">
                {props.primary.disabled.toString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {props.secondary.keyId}
              </TableCell>
              <TableCell align="right">{props.secondary.keyName}</TableCell>
              <TableCell align="right">{props.secondary.domainId}</TableCell>
              <TableCell>{props.secondary.publickey}</TableCell>
              <TableCell align="right">
                {props.secondary.disabled.toString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
