import React, { useState, useEffect } from 'react';
import * as api from '../api/postdata';
import {
  defaultPostDataList,
  PostData,
  defaultPostData,
  SearchCondition,
  defaultSearchCondition,
} from 'domain/PostData';
import { List } from 'components/PostData/List';
import { Detail } from 'components/PostData/Detail';
import { Search } from 'components/PostData/Search';
import { CountInfo } from 'components/molecules/CountInfo';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

export const PostDataControl: React.FC = () => {
  const classes = useStyles();
  const [list, setList] = useState(defaultPostDataList);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [condition, setSearchCondtion] = useState(defaultSearchCondition);
  const history = useHistory();

  /**
   *検索を実行する
   */
  const executeSearch = async (cond: SearchCondition) => {
    const data = await api.list(cond);
    setList(data);
  };

  /**
   * 詳細情報を表示する
   */
  const openDetail = (index: number) => {
    setSelectedIndex(index);
  };

  /**
   * 詳細情報を非表示にする
   */
  const closeDetail = (): void => {
    setSelectedIndex(-1);
  };

  /**
   * 検索条件を変更する
   * @param data 新しい検索条件
   */
  const changeSearchCondition = (data: SearchCondition) => {
    setSearchCondtion(data);
  };

  /**
   * 選択したindexのログを返す
   */
  const selectedDetail = (): PostData => {
    if (selectedIndex < 0) {
      return defaultPostData;
    }
    return list.logs[selectedIndex];
  };

  /**
   * 初期状態の取得など
   */
  useEffect(() => {
    const url = new URL(window.location.href);
    const param = url.searchParams.get('condition');
    if (param === null) {
      return;
    }
    const cond = JSON.parse(param);
    setSearchCondtion(cond);

    executeSearch(cond);
  }, []);

  /**
   * condition変更時の処理
   */
  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('condition', JSON.stringify(condition));
    history.push('/base/postdata/?' + url.searchParams.toString());
  }, [condition, history]);

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="h4" component="h2">
            PostData
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Search
            data={condition}
            updateSearchCondition={changeSearchCondition}
            executeSearch={executeSearch}
          />
          <CountInfo data={list.logs} scanndInBytes={list.scannedInBytes} />
          <List data={list.logs} openDetail={openDetail} />
        </Grid>
        <Grid item sm={12} lg={4}>
          <Paper>
            <Detail data={selectedDetail()} closeDetail={closeDetail} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
