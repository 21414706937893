import React from 'react';
import { Resource } from '../../domain/InfraResource';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * インフラリソースの一覧表示データ
   */
  data: Resource[];

  /**
   * 詳細情報を表示するためのインターフェース
   */
  openDetail: (amiId: string) => void;
};

export const List: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">ResourceId</TableCell>
              <TableCell align="right">ResourceType</TableCell>
              <TableCell align="right">AMIMonitoredAt</TableCell>
              <TableCell align="right">Tags</TableCell>
              <TableCell align="right">Levels</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((d, i) => (
              <TableRow
                key={d.resourceId}
                hover
                onClick={() => props.openDetail(props.data[i].resourceId)}
              >
                <TableCell component="th" scope="row">
                  {d.resourceId}
                </TableCell>
                <TableCell align="right">{d.resourceType}</TableCell>
                <TableCell align="right">{DateFormat(d.monitoredAt)}</TableCell>
                <TableCell align="right">
                  <ul>
                    {d.tags.map((t) => {
                      return (
                        <li key={t.tagKey}>
                          {t.tagKey}: {t.tagValue}
                        </li>
                      );
                    })}
                  </ul>
                </TableCell>
                <TableCell align="right">
                  <ul>
                    {d.levels.map((level) => {
                      return (
                        <li key={level.downstreamResourceId}>
                          {level.downstreamResourceId}
                        </li>
                      );
                    })}
                  </ul>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => props.openDetail(props.data[i].resourceId)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
