export type ResourceType = 'alb' | 'clb' | 'cf' | 'ec2' | 'rds' | '';

export type Resource = {
  resourceId: string;
  resourceType: ResourceType;
  monitoredAt: string;
  tags: Tag[];
  levels: Level[];
};

export const defaultResource: Resource = {
  resourceId: '',
  resourceType: 'ec2',
  monitoredAt: '',
  tags: [],
  levels: [],
};

export type Tag = {
  tagKey: string;
  tagValue: string;
};

export type Level = {
  downstreamResourceId: string;
};

export type Alive = {
  resourceId: string;
  resourceType: ResourceType;
  monitoredAt: string;
  alive: Metrics[] | null;
};

export const defaultAlive: Alive = {
  resourceId: '',
  resourceType: '',
  monitoredAt: '',
  alive: [],
};

export type Metrics = {
  resourceId: string;
  resourceType: ResourceType;
  monitoredAt: string;
  cpuUtilization: number;
  networkIn: number;
  networkOut: number;
  freeableMemory: number;
  networkReceiveThroughput: number;
  networkTransmitThroughput: number;
};

export const defaultMetrics: Metrics = {
  resourceId: '',
  resourceType: '',
  monitoredAt: '',
  cpuUtilization: 0,
  networkIn: 0,
  networkOut: 0,
  freeableMemory: 0,
  networkReceiveThroughput: 0,
  networkTransmitThroughput: 0,
};

export type SearchCondition = {
  match: string;
  limit: number;
  offset: number;
};

export const defaultSearchCondition: SearchCondition = {
  match: '',
  limit: 0,
  offset: 0,
};

/**
 * インフラリソース監視の詳細検索フィールド
 */
export const advancedSearchFields = [];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string
): SearchCondition => {
  switch (key) {
    case 'match':
      return { ...cond, match: value };
    case 'limit':
      return { ...cond, limit: parseInt(value) };
    case 'offset':
      return { ...cond, offset: parseInt(value) };
  }
};
