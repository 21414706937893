import React from 'react';
import { ErrorLog } from '../../domain/ErrorLog';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * 操作ログの一覧表示データ
   */
  data: ErrorLog[];

  /**
   * 詳細情報を表示するためのインターフェース
   */
  openDetail: (id: number) => void;
};

export const List: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Function</TableCell>
              <TableCell align="right">RequestId</TableCell>
              <TableCell align="right">URL</TableCell>
              <TableCell align="right">Level</TableCell>
              <TableCell align="right">Code</TableCell>
              <TableCell align="right" className={classes.uriCell}>
                Description
              </TableCell>
              <TableCell align="right">OccurredAt</TableCell>
              <TableCell align="right">Customer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((d, i) => (
              <TableRow key={d.id} hover onClick={() => props.openDetail(i)}>
                <TableCell align="right">{d.product}</TableCell>
                <TableCell align="right">{d.function}</TableCell>
                <TableCell align="right">{d.requestId}</TableCell>
                <TableCell align="right">{d.url}</TableCell>
                <TableCell align="right">{d.level}</TableCell>
                <TableCell align="right">{d.code}</TableCell>
                <TableCell align="right">{d.description}</TableCell>
                <TableCell align="right">{DateFormat(d.occurredAt)}</TableCell>
                <TableCell align="right">
                  {d.customerDomain}
                  <br />
                  {d.customerName}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => props.openDetail(i)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  uriCell: {
    wordBreak: 'break-all',
  },
});
