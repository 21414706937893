class Session {
  private asLogin = "asLogin";

  public isLogin = (): boolean => {
    return !!window.localStorage.getItem(this.asLogin);
  };

  public setLogin = () => {
    window.localStorage.setItem(this.asLogin, "true");
  };

  public unsetLogin = () => {
    window.localStorage.removeItem(this.asLogin);
  };
}

export default new Session();
