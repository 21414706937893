import React from 'react';
import { AMI } from '../../domain/AMI';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * AMIの一覧表示データ
   */
  data: AMI[];

  /**
   * 詳細情報を表示するためのインターフェース
   */
  openDetail: (amiId: string) => void;
};

export const List: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">AMIId</TableCell>
              <TableCell align="right">AMIName</TableCell>
              <TableCell align="right">AMICreatedAt</TableCell>
              <TableCell align="right">AMIMonitoredAt</TableCell>
              <TableCell align="right">Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((d, i) => (
              <TableRow
                key={d.amiId}
                hover
                onClick={() => props.openDetail(props.data[i].amiId)}
              >
                <TableCell component="th" scope="row">
                  {d.amiId}
                </TableCell>
                <TableCell align="right">{d.amiName}</TableCell>
                <TableCell align="right">
                  {DateFormat(d.amiCreatedAt)}
                </TableCell>
                <TableCell align="right">{DateFormat(d.monitoredAt)}</TableCell>
                <TableCell align="right">
                  <ul>
                    {d.tags.map((t) => {
                      return (
                        <li key={t.tagKey}>
                          {t.tagKey}: {t.tagValue}
                        </li>
                      );
                    })}
                  </ul>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => props.openDetail(props.data[i].amiId)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
