/**
 * backend
 */

export type ResourceType = 'alb' | 'clb' | 'cf' | 'ec2' | 'rds' | '';

export type Resource = {
  resourceId: string;
  resourceType: ResourceType;
  monitoredAt: string;
  tags: Tag[];
  levels: Level[];
};

export type Tag = {
  tagKey: string;
  tagValue: string;
};

export type Level = {
  downstreamResourceId: string;
};

export const defaultResource: Resource = {
  resourceId: '',
  resourceType: '',
  monitoredAt: '',
  tags: [],
  levels: [],
};

/**
 * logical objects
 */
export type Node = {
  type: ResourceType;
  indexPerType: number;
  id: string;
};

export const defaultNode: Node = {
  type: '',
  indexPerType: 0,
  id: '',
};

export type Link = {
  fromType: ResourceType;
  fromId: string;
  toType: ResourceType;
  toId: string;
};

export type Fix = {
  fixX: number;
  fixY: number;
};
/**
 * display
 */

export type Rect = {
  id: string;
  type: ResourceType;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
};

export type Path = {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  color: string;
};

export const defaultPath: Path = {
  fromX: 0,
  fromY: 0,
  toX: 0,
  toY: 0,
  color: '',
};
