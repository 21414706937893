import React, { useState } from 'react';
import {
  SearchCondition,
  defaultSearchCondition,
  changeSearchCondition,
} from '../../domain/AMI';
import { Button, TextField } from '@material-ui/core';

type Props = {
  /**
   * コントロール側の検索条件に変更を反映するインターフェース
   */
  updateSearchCondition: (data: SearchCondition) => void;
};

export const Search: React.FC<Props> = (props: Props) => {
  const [formCondition, setFormCondition] = useState(defaultSearchCondition);

  /**
   * 検索条件変更処理を実行する
   * @param e イベント
   */
  const handleChangeCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const cond = changeSearchCondition(
      formCondition,
      e.target.id as keyof SearchCondition,
      e.target.value
    );

    setFormCondition(cond);
  };

  /**
   * 検索を実行する
   * @param e イベント
   */
  const handleSubmitSearch = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    props.updateSearchCondition(formCondition);
  };

  return (
    <form onSubmit={handleSubmitSearch}>
      <TextField
        id="match"
        label="Keyword"
        type="text"
        value={formCondition.match}
        onChange={handleChangeCondition}
      />
      <Button type="submit" variant="contained">
        Search
      </Button>
    </form>
  );
};
