import React from 'react';
import { Redirect } from 'react-router-dom';
import Session from './store/session';

const Auth: React.FC = (props: any) => {
  if (Session.isLogin()) {
    //サインインしてるとき（そのまま表示）
    return props.children;
  }
  //してないとき（ログイン画面にリダイレクト）
  return <Redirect to="/login" />;
}

export default Auth;