import React from 'react';
import { Alive } from '../../domain/InfraResource';
import { Card, CardHeader, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

type Props = {
  /**
   * 死活情報データ
   */
  data: Alive;

  /**
   * 死活情報を非表示にするためのインターフェース
   */
  closeAlive: () => void;
};

export const AliveMonitor: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const d = props.data;

  if (!d.alive || d.alive.length === 0) return <div />;
  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <div aria-label="settings">
            <IconButton aria-label="settings" onClick={props.closeAlive}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <div
        style={{
          padding: '5px 30px 50px 20px',
          overflow: 'scroll',
          height: '100%',
        }}
      >
        <Typography variant="h4" component="h3">
          {d.resourceId}
        </Typography>
        {(() => {
          if (d.resourceType === 'ec2' || d.resourceType === 'rds')
            return (
              <LineChart width={500} height={300} data={d.alive}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monitoredAt" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="cpuUtilization"
                  stroke="#8884d8"
                />
              </LineChart>
            );
        })()}

        {(() => {
          if (d.resourceType === 'ec2')
            return (
              <LineChart width={500} height={300} data={d.alive}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monitoredAt" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="networkIn" stroke="#8884d8" />
              </LineChart>
            );
        })()}

        {(() => {
          if (d.resourceType === 'ec2')
            return (
              <LineChart width={500} height={300} data={d.alive}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monitoredAt" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="networkOut" stroke="#8884d8" />
              </LineChart>
            );
        })()}

        {(() => {
          if (d.resourceType === 'rds')
            return (
              <LineChart width={500} height={300} data={d.alive}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monitoredAt" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="freeableMemory"
                  stroke="#82ca9d"
                />
              </LineChart>
            );
        })()}

        {(() => {
          if (d.resourceType === 'rds')
            return (
              <LineChart width={500} height={300} data={d.alive}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monitoredAt" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="networkReceiveThroughput"
                  stroke="#8884d8"
                />
              </LineChart>
            );
        })()}

        {(() => {
          if (d.resourceType === 'rds')
            return (
              <LineChart width={500} height={300} data={d.alive}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monitoredAt" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="networkTransmitThroughput"
                  stroke="#8884d8"
                />
              </LineChart>
            );
        })()}
      </div>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    width: '600px',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
    height: '100%',
    overflowY: 'auto',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
