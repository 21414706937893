import { get, post, loginPost } from './io';
import { dateUtcFormat } from '../domain/dateFormat';
import { LoginChallengeResponse, LoginResponse } from '../domain/Response';
import {
  AccountForm,
  SearchCondition as AccountSearch,
  Account,
} from '../domain/Userbase/Account';
import { Group } from '../domain/Userbase/Group';
import { RSAKey } from '../domain/Userbase/Key';

const rootUrl =
  document.location.protocol + '//' + document.location.host + '/base_api';
const origin = window.location.protocol + '//' + window.location.host;

export const passwordLoginChallenge = async (
  loginId: string
): Promise<LoginChallengeResponse> => {
  const url = rootUrl + '/userbase/auth/password-challenge';
  const params = {
    loginId,
  };
  const ret: LoginChallengeResponse = await post(url, params);
  return Promise.resolve(ret);
};

export const passwordLogin = async (
  loginId: string,
  loginHash: string
): Promise<LoginResponse> => {
  const url = rootUrl + '/userbase/auth/password';
  const params = {
    loginId,
    loginHash: loginHash,
  };
  const ret: LoginResponse = await loginPost(url, params);
  return Promise.resolve(ret);
};

export const verify = async (): Promise<Account> => {
  const url = rootUrl + '/userbase/auth/verify';

  const ret = await post(url);
  return Promise.resolve(ret.account);
};

export const logout = async (): Promise<void> => {
  const url = rootUrl + '/userbase/auth/logout';

  await post(url);
  return Promise.resolve();
};

export const accountList = async (
  params: AccountSearch
): Promise<Array<Account>> => {
  const url = rootUrl + '/userbase/account/list';
  const ret: Account[] = await post(url, params);
  return Promise.resolve(ret);
};

export const accountDescribe = async (id: number): Promise<Account> => {
  const params = {
    id,
  };
  const url = rootUrl + '/userbase/account/describe';
  const ret: Account = await post(url, params);
  return Promise.resolve(ret);
};

export const accountAdd = async (account: AccountForm): Promise<Account> => {
  const url = rootUrl + '/userbase/account/add';

  const data: Account = {
    accountId: account.accountId,
    loginId: account.loginId,
    email: account.email,
    familyName: account.familyName,
    givenName: account.givenName,
    beginDate: dateUtcFormat(account.beginDate),
    endDate: dateUtcFormat(account.endDate),
    forceDisable: account.forceDisable,
    deleted: account.deleted,
    denyDelete: account.denyDelete,
  };

  const params = {
    account: data,
  };
  const ret = await post(url, params);

  return Promise.resolve(ret);
};

export const accountUpdate = async (account: AccountForm): Promise<Account> => {
  const url = rootUrl + '/userbase/account/update';

  const data: Account = {
    accountId: account.accountId,
    loginId: account.loginId,
    email: account.email,
    familyName: account.familyName,
    givenName: account.givenName,
    beginDate: dateUtcFormat(account.beginDate),
    endDate: dateUtcFormat(account.endDate),
    forceDisable: account.forceDisable,
    deleted: account.deleted,
    denyDelete: account.denyDelete,
  };

  const params = {
    account: data,
  };
  const ret = await post(url, params);

  return Promise.resolve(ret);
};

export const accountDelete = async (accountId: number): Promise<void> => {
  const url = rootUrl + '/userbase/account/delete';
  const params = {
    accountId,
  };
  await post(url, params);
  return Promise.resolve();
};

export const groupList = async (): Promise<Array<Group>> => {
  const url = rootUrl + '/userbase/group/list';
  const params = {};
  const ret: Group[] = await post(url, params);
  return Promise.resolve(ret);
};

export const primaryKey = async (): Promise<RSAKey> => {
  const url = rootUrl + '/userbase/keymanager/publickey/primary';
  const params = {};
  const ret: RSAKey = await post(url, params);
  return Promise.resolve(ret);
};

export const secondaryKey = async (): Promise<RSAKey> => {
  const url = rootUrl + '/userbase/keymanager/publickey/secondary';
  const params = {};
  const ret: RSAKey = await post(url, params);
  return Promise.resolve(ret);
};

export const generateKey = async (): Promise<void> => {
  const url = rootUrl + '/userbase/keymanager/publickey/generate';
  const params = {};
  await post(url, params);
  return Promise.resolve();
};

export const revertKey = async (): Promise<void> => {
  const url = rootUrl + '/userbase/keymanager/publickey/revert';
  const params = {};
  await post(url, params);
  return Promise.resolve();
};

export const googleLogin = async (): Promise<string> => {
  const url = rootUrl + '/userbase/auth/google';

  const params = new URLSearchParams();
  params.set('origin', origin);

  const ret = await post(url + '?' + params.toString());
  return Promise.resolve(ret.redirectUrl);
};

export const googleLoginCallback = async (paramsStr: string): Promise<any> => {
  const url = rootUrl + '/userbase/auth/google/callback';

  const params = new URLSearchParams(paramsStr);
  params.set('origin', origin);

  const ret = await get(url + '?' + params.toString());
  return Promise.resolve(ret);
};
