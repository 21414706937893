import React, { useState, useEffect, useCallback } from 'react';
import * as api from '../api/inframonitor';
import * as systemLogApi from '../api/systemlog';
import {
  Resource,
  Level,
  defaultResource,
  defaultAlive,
  defaultSearchCondition,
} from '../domain/InfraResource';
import {
  SystemLog,
  defaultSearchCondition as systemlogDefaultSearchCondition,
} from '../domain/SystemLog';
import {
  ResourceType,
  Node,
  defaultPath,
  Rect,
  Path,
} from '../domain/InfraTree';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AliveMonitor } from '../components/InfraResource/AliveMonitor';
import { SystemLogMonitor } from '../components/InfraResource/SystemLogMonitor';
import { useHistory } from 'react-router-dom';
import { flattenDeep } from 'lodash';

export const InfraTreeControl: React.FC = () => {
  const [rects, setRects] = useState([] as Rect[]);
  const [paths, setPaths] = useState([] as Path[]);
  const [alive, setAlive] = useState(defaultAlive);
  const [extractId, setExtractId] = useState('');
  const [systemLogs, setSystemLogs] = useState([] as SystemLog[]);
  const history = useHistory();

  const width = 260;
  const height = 160;
  const paddingX = 50;
  const paddingY = 50;
  const black = '#383838';
  const gray = '#e0e0e0';
  const red = '#ff2626';

  /**
   *検索を実行する
   */
  const executeSearch = useCallback(async () => {
    let resources = (await api.resourceList(defaultSearchCondition)) || [];
    const nodes = ((): Node[] => {
      let cfCnt = 0;
      let lbCnt = 0;
      let ec2Cnt = 0;
      let rdsCnt = 0;

      let displayResources;
      if (extractId !== '') {
        var childrens: Level[] = [];

        displayResources = resources.filter((r) => {
          // 指定したリソース自身である
          if (r.resourceId === extractId) {
            childrens = r.levels;
            return true;
          }
          // 指定したリソースの親である
          return r.levels.some((l) => {
            return l.downstreamResourceId === extractId;
          });
        });
        // 指定したリソースの子である
        childrens.forEach((l: Level) => {
          const r = searchResource(resources, l.downstreamResourceId);
          displayResources.push(r);
        });
      } else {
        displayResources = resources;
      }

      resources = displayResources;
      console.log(resources);

      let count: number;
      return resources
        .map((resource) => {
          switch (resource.resourceType) {
            case 'cf':
              count = cfCnt;
              cfCnt++;
              break;
            case 'alb':
            case 'clb':
              count = lbCnt;
              lbCnt++;
              break;
            case 'ec2':
              count = ec2Cnt;
              ec2Cnt++;
              break;
            case 'rds':
              count = rdsCnt;
              rdsCnt++;
              break;
          }
          return {
            type: resource.resourceType,
            indexPerType: count,
            id: resource.resourceId,
          };
        })
        .filter(Boolean);
    })();

    const leveledLinks = resources.map((resource) => {
      return resource.levels.map((level) => {
        const to = searchResource(resources, level.downstreamResourceId);
        return {
          fromType: resource.resourceType,
          fromId: resource.resourceId,
          toType: to.resourceType,
          toId: to.resourceId,
        };
      });
    });
    const links = flattenDeep(leveledLinks);
    let rects: Rect[] = [];

    nodes.forEach((node, i) => {
      if (!node) return;

      let fixX = fixConstant(node.type);
      let fixY = node.indexPerType;

      rects.push({
        id: node.id,
        type: node.type,
        x: paddingX + width * 2 * fixX,
        y: paddingY + (paddingY + height) * fixY,
        width: width,
        height: height,
        color: black,
      });
    });

    const paths: Path[] = links.map((link, i) => {
      let idx: Node | undefined;

      // from
      let fixX = fixConstant(link.fromType);
      idx = nodes.find(
        (node) => node.id === link.fromId && node.type === link.fromType
      );
      if (!idx) return defaultPath;
      const fromX = paddingX + width * (fixX * 2 + 1);
      const fromY =
        paddingY + (paddingY + height) * idx.indexPerType + height / 2;

      // to
      fixX = fixConstant(link.toType);
      idx = nodes.find(
        (node) => node.id === link.toId && node.type === link.toType
      );
      if (!idx) return defaultPath;
      const toX = paddingX + width * (fixX * 2);
      const toY =
        paddingY + (paddingY + height) * idx.indexPerType + height / 2;

      return {
        fromX,
        fromY,
        toX,
        toY,
        color: gray,
      };
    });

    setRects(rects);
    setPaths(paths);
  }, [extractId]);

  /**
   * 死活情報を表示する
   */
  const openAlive = useCallback(
    async (resourceId: string) => {
      history.push('/base/infratree/alive/' + resourceId);
      const alive = await api.aliveDescribe(resourceId);
      setAlive(alive);
    },
    [history]
  );

  /**
   * 死活情報を非表示にする
   */
  const closeAlive = (): void => {
    history.push('/base/infratree/');
    setAlive(defaultAlive);
  };

  /**
   * システムログを表示する
   */
  const openSystemLog = useCallback(
    async (resourceId: string) => {
      history.push('/base/infratree/systemlog/' + resourceId);

      const cond = systemlogDefaultSearchCondition;

      const systemlogs = await systemLogApi.systemLogList(cond);
      setSystemLogs(systemlogs);
    },
    [history]
  );

  /**
   * 死活情報を非表示にする
   */
  const closeSystemLog = (): void => {
    history.push('/base/infratree/');
    setSystemLogs([] as SystemLog[]);
  };

  /**
   * 初期状態の取得など
   */
  useEffect(() => {
    executeSearch();
  }, [executeSearch]);

  return (
    <div>
      {(() => {
        if (extractId !== '') {
          return (
            <div style={{ position: 'fixed', right: 0 }}>
              <IconButton
                onClick={() => {
                  setExtractId('');
                }}
              >
                <HighlightOffIcon style={{ color: '#fff', fontSize: 36 }} />
              </IconButton>
            </div>
          );
        }
      })()}
      <AliveMonitor data={alive} closeAlive={closeAlive} />
      <SystemLogMonitor list={systemLogs} closeSystemLog={closeSystemLog} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          minWidth: '2000px',
          width: '100%',
          minHeight: (height + paddingY) * rects.length,
          height: '100%',
          background: black,
          overflow: 'scroll',
        }}
      >
        {rects.map((r) => {
          return (
            <g transform={'translate(' + r.x + ', ' + r.y + ')'} key={r.id}>
              <rect width={r.width} height={r.height} fill={r.color} />
              <foreignObject width={r.width} height={r.height}>
                <Card
                  onClick={() => {
                    setExtractId(r.id);
                  }}
                  // for Demo
                  style={{
                    background:
                      r.id ===
                      'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Platon1100/2480b750813a9f1d'
                        ? red
                        : gray,
                    color:
                      r.id ===
                      'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Platon1100/2480b750813a9f1d'
                        ? '#fff'
                        : '',
                  }}
                >
                  <CardContent style={{ padding: 5 }}>
                    <Typography variant="h5" component="h4">
                      {r.type}
                    </Typography>
                    <br />
                    <div style={{ wordBreak: 'break-all' }}>{r.id}</div>
                  </CardContent>
                  <CardActions style={{ padding: 5 }}>
                    {(() => {
                      if (r.type === 'ec2' || r.type === 'rds') {
                        return (
                          <Button
                            size="small"
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              openAlive(r.id);
                            }}
                          >
                            metrics
                          </Button>
                        );
                      }
                    })()}

                    {(() => {
                      return (
                        <Button
                          size="small"
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            openSystemLog(r.id);
                          }}
                          style={{
                            color:
                              r.id ===
                              'arn:aws:elasticloadbalancing:us-west-2:294756904836:loadbalancer/app/Platon1100/2480b750813a9f1d'
                                ? '#fff'
                                : '',
                          }}
                        >
                          log
                        </Button>
                      );
                    })()}
                  </CardActions>
                </Card>
              </foreignObject>
            </g>
          );
        })}

        {paths.map((path) => {
          const d =
            'M ' +
            path.fromX +
            ' ' +
            path.fromY +
            ' L ' +
            path.toX +
            ' ' +
            path.toY;

          return <path d={d} stroke={path.color} strokeWidth={3} key={d} />;
        })}
      </svg>
    </div>
  );
};

const searchResource = (resources: Resource[], searchId: string): Resource => {
  return (
    resources.find((resource) => resource.resourceId === searchId) ||
    defaultResource
  );
};

const fixConstant = (type: ResourceType): number => {
  switch (type) {
    case 'cf':
      return 0;
    case 'alb':
    case 'clb':
      return 1;
    case 'ec2':
      return 2;
    case 'rds':
      return 3;
    default:
      return -1;
  }
};
