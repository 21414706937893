import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatBytes } from 'domain/Format';
interface Props<T> {
  data: T[];
  scanndInBytes: number;
}

type CountInfoType = <T>(props: Props<T>) => React.ReactElement<Props<T>>;

/**
 * 一覧データの件数情報などを表示するコンポーネント
 * @param props
 */
export const CountInfo: CountInfoType = (props) => {
  const classes = useStyles();

  /**
   * 入力した件数によって、表示を変える
   * @param input
   */
  const displayCount = (input: number): React.ReactElement => {
    if (input < 1000) {
      return (
        <>
          <span className={classes.count}>{props.data.length}</span> 件
        </>
      );
    }
    return (
      <>
        <span className={classes.overflow}>999+</span> 件<br />
        <span className={classes.text}>条件を絞り込んで下さい。</span>
      </>
    );
  };
  /**
   * 入力したバイト数によって、表示を変える
   * @param input
   */
  const displayScanned = (input: number): React.ReactElement => {
    if (input === 0) {
      return <></>;
    }
    return <> / {formatBytes(input)} scanned </>;
  };

  return (
    <div className={classes.container}>
      {displayCount(props.data.length)} {displayScanned(props.scanndInBytes)}
    </div>
  );
};

const useStyles = makeStyles({
  container: { textAlign: 'right', marginRight: '20px' },
  count: {
    fontSize: '1.4rem',
  },
  overflow: { fontSize: '1.4rem', color: 'red' },
  text: {
    fontSize: '0.8rem',
  },
});
