import { Color } from "@material-ui/lab/Alert";

export type Message = {
  severity: Color;
  text: string;
};

export const initialState = { messages: [] as Array<Message> };
export type State = typeof initialState;

export type Action =
  | {
      type: "add_message";
      message: Message;
    }
  | {
      type: "remove_message";
    };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "add_message": {
      const newMessages = [...state.messages];
      newMessages.push(action.message);
      return {
        ...state,
        messages: newMessages,
      };
    }
    case "remove_message": {
      const newMessages = [...state.messages];
      newMessages.shift();
      return {
        ...state,
        messages: newMessages,
      };
    }
  }
  return state;
};
