import React, { useState, useEffect, useCallback } from 'react';
import * as api from '../api/userbase';
import {
  Account,
  AccountForm,
  defaultAccount,
  defaultSearchCondition,
  defaultAccountForm,
} from '../domain/Userbase/Account';
import { dateFormat } from '../domain/dateFormat';
import { Detail } from '../components/Account/Detail';
import { List } from '../components/Account/List';
import { New } from '../components/Account/New';
import { Edit } from '../components/Account/Edit';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

export const DomainControl: React.FC = () => {
  const classes = useStyles();
  const [list, setList] = useState([] as Account[]);
  const [detail, setDetail] = useState(defaultAccount);
  const [edit, setEdit] = useState(defaultAccountForm);
  const condition = defaultSearchCondition;
  const history = useHistory();

  /**
   * 検索を実行する
   * @param cond 検索条件
   */
  const executeSearch = useCallback(async () => {
    const data = (await api.accountList(condition)) || [];
    setList(data);
  }, [condition]);

  /**
   *　詳細情報を表示する
   */
  const openDetail = useCallback(
    async (accountId: number) => {
      history.push('/base/userbase-account/detail/' + accountId.toString());
      const detail = await api.accountDescribe(accountId);
      setDetail(detail);
    },
    [history]
  );

  /**
   * 詳細情報を非表示にする
   */
  const closeDetail = (): void => {
    history.push('/base/userbase-account/');
    setDetail(defaultAccount);
  };

  /**
   * 編集ダイアログを表示する
   */
  const openEdit = (): void => {
    const form = {
      ...detail,
      beginDate: dateFormat(detail.beginDate),
      endDate: dateFormat(detail.endDate),
    } as AccountForm;
    setEdit(form);
  };

  const closeEdit = () => {
    setEdit(defaultAccountForm);
  };

  const changeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setEdit((prevState: AccountForm) => {
      switch (e.target.name) {
        case 'loginId':
          return { ...prevState, loginId: e.target.value };
        case 'email':
          return { ...prevState, email: e.target.value };
        case 'familyName':
          return { ...prevState, familyName: e.target.value };
        case 'givenName':
          return { ...prevState, givenName: e.target.value };
        case 'beginDate':
          return { ...prevState, beginDate: e.target.value };
        case 'endDate':
          return { ...prevState, endDate: e.target.value };
        case 'forceDisable':
          return {
            ...prevState,
            forceDisable: e.target.value === 'true' ? true : false,
          };
        default:
          return prevState;
      }
    });
  };

  /**
   * 初期状態の取得など
   */
  useEffect(() => {
    executeSearch();

    const paths = document.location.pathname.split('/');
    if (paths.length > 4) {
      if (paths[3] === 'detail') {
        openDetail(parseInt(paths[4]));
      }
    }
  }, [executeSearch, openDetail]);

  return (
    <div className={classes.container}>
      <New reloadList={executeSearch} />
      <Edit
        data={edit}
        reloadList={executeSearch}
        changeEdit={changeEdit}
        closeEdit={closeEdit}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="h4" component="h2">
            Account
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <List data={list} openDetail={openDetail} />
        </Grid>
      </Grid>
      <Detail data={detail} closeDetail={closeDetail} openEdit={openEdit} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
