import { Datetime, newDate } from 'domain/dateFormat';

/**
 * アクセスログのデータ型
 */
export type AccessLog = {
  requestId: string;
  datetime: string;
  clientIp: string;
  method: string;
  scheme: string;
  host: string;
  path: string;
  query: string;
  status: string;
  accountId: string;
  loginId: string;
};

/**
 * アクセスログの初期値
 */
export const defaultAccessLog: AccessLog = {
  requestId: '',
  datetime: '',
  clientIp: '',
  method: '',
  scheme: '',
  host: '',
  path: '',
  query: '',
  status: '',
  accountId: '',
  loginId: '',
};

export type AccessLogList = {
  logs: AccessLog[];
  scannedInBytes: number;
};

export const defaultAccessLogList = {
  logs: [] as AccessLog[],
  scannedInBytes: 0,
};

export type AccessLogDescribe = {
  log: AccessLog;
  scannedInBytes: number;
};

/**
 * アクセスログの検索データ型
 */
export type SearchCondition = {
  match: string;
  requestId: string;
  datetimeStart: string;
  datetimeEnd: string;
  clientIp: string;
  method: string;
  scheme: string;
  host: string;
  path: string;
  query: string;
  statuses: string[];
  accountId: number;
  loginId: string;
};

/**
 * アクセスログ検索の初期値
 */
export const defaultSearchCondition: SearchCondition = {
  match: '',
  requestId: '',
  datetimeStart: newDate().toISOString(),
  datetimeEnd: newDate().toISOString(),
  clientIp: '',
  method: '',
  scheme: '',
  host: '',
  path: '',
  query: '',
  statuses: [],
  accountId: 0,
  loginId: '',
};

/**
 * アクセスログの詳細検索フィールド
 */
export const advancedSearchFields = [
  {
    input: 'TextField',
    id: 'requestId',
    label: 'RequestID',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'clientIp',
    label: 'ClientIP',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'method',
    label: 'Method',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'scheme',
    label: 'Scheme',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'host',
    label: 'Host',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'path',
    label: 'Path',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'query',
    label: 'Query',
    type: 'text',
  },
  {
    input: 'HttpStatusSelector',
    id: 'statuses',
    label: 'Statuses',
    type: 'custom',
  },
  {
    input: 'TextField',
    id: 'accountId',
    label: 'AccountID',
    type: 'number',
  },
  {
    input: 'TextField',
    id: 'loginId',
    label: 'LoginID',
    type: 'text',
  },
];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string | string[] | number | Datetime
): SearchCondition => {
  switch (key) {
    case 'match':
      return { ...cond, match: value as string };
    case 'requestId':
      return { ...cond, requestId: value as string };
    case 'datetimeStart':
      if (!value) return cond;
      return { ...cond, datetimeStart: value as string };
    case 'datetimeEnd':
      if (!value) return cond;
      return { ...cond, datetimeEnd: value as string };
    case 'clientIp':
      return { ...cond, clientIp: value as string };
    case 'method':
      return { ...cond, method: value as string };
    case 'scheme':
      return { ...cond, scheme: value as string };
    case 'host':
      return { ...cond, host: value as string };
    case 'path':
      return { ...cond, path: value as string };
    case 'query':
      return { ...cond, query: value as string };
    case 'statuses':
      return { ...cond, statuses: value as string[] };
    case 'accountId':
      return { ...cond, accountId: value as number };
    case 'loginId':
      return { ...cond, loginId: value as string };
  }
};
