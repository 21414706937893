import { post } from './io';
import {
  PostDataList,
  PostDataDescribe,
  SearchCondition,
} from '../domain/PostData';
import dayjs from 'dayjs';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const list = async (cond: SearchCondition): Promise<PostDataList> => {
  const url = rootUrl + '/base_api/postdata/list';
  const params = {
    ...cond,
    datetimeEnd: dayjs(cond.datetimeEnd).add(1, 'day').toISOString(),
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const describe = async (
  requestId: string
): Promise<PostDataDescribe> => {
  const url = rootUrl + '/base_api/postdata/describe';
  const params = {
    requestId,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};
