import { post } from './io';
import { ActionLog, SearchCondition } from '../domain/ActionLog';
import dayjs from 'dayjs';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const list = async (
  cond: SearchCondition
): Promise<Array<ActionLog>> => {
  const url = rootUrl + '/base_api/actionlog/list';
  const params = {
    ...cond,
    occurredAtEnd: dayjs(cond.occurredAtEnd).add(1, 'day').toISOString(),
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const describe = async (id: number): Promise<ActionLog> => {
  const url = rootUrl + '/base_api/actionlog/describe';
  const params = {
    id,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const add = async (log: ActionLog): Promise<ActionLog> => {
  const url = rootUrl + '/base_api/actionlog/add';

  const params = {
    actionlog: log,
  };
  const ret = await post(url, params);

  return Promise.resolve(ret);
};
