import { Datetime, newDate } from 'domain/dateFormat';

export type PostData = {
  requestId: string;
  requestDatetime: string;
  requestContentType: string;
  requestBody: string;
  clientIp: string;
  method: string;
  scheme: string;
  host: string;
  path: string;
  query: string;
  referer: string;
  userAgent: string;
  cookie: string;
  responseDatetime: string;
  timeTakenMilliSeconds: number;
  status: number;
};

export const defaultPostData: PostData = {
  requestId: '',
  requestDatetime: '',
  requestContentType: '',
  requestBody: '',
  clientIp: '',
  method: '',
  scheme: '',
  host: '',
  path: '',
  query: '',
  referer: '',
  userAgent: '',
  cookie: '',
  responseDatetime: '',
  timeTakenMilliSeconds: 0,
  status: 0,
};

export type PostDataList = {
  logs: PostData[];
  scannedInBytes: number;
};

export const defaultPostDataList = {
  logs: [] as PostData[],
  scannedInBytes: 0,
};

export type PostDataDescribe = {
  log: PostData;
  scannedInBytes: number;
};

export type SearchCondition = {
  match: string;
  requestId: string;
  datetimeStart: string;
  datetimeEnd: string;
  requestContentType: string;
  requestBody: string;
  clientIp: string;
  method: string;
  scheme: string;
  host: string;
  path: string;
  query: string;
  referer: string;
  userAgent: string;
  cookie: string;
  timeTakenMilliSecondsMin: number;
  timeTakenMilliSecondsMax: number;
  statuses: string[];
  accountId: number;
  loginId: string;
  limit: number;
};

export const defaultSearchCondition: SearchCondition = {
  match: '',
  requestId: '',
  datetimeStart: newDate().toISOString(),
  datetimeEnd: newDate().toISOString(),
  requestContentType: '',
  requestBody: '',
  clientIp: '',
  method: '',
  scheme: '',
  host: '',
  path: '',
  query: '',
  referer: '',
  userAgent: '',
  cookie: '',
  timeTakenMilliSecondsMin: 0,
  timeTakenMilliSecondsMax: 0,
  statuses: [],
  accountId: 0,
  loginId: '',
  limit: 0,
};

/**
 * Postログの詳細検索フィールド
 */
export const advancedSearchFields = [
  {
    input: 'TextField',
    id: 'requestId',
    label: 'RequestID',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'requestContentType',
    label: 'RequestContentType',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'requestBody',
    label: 'RequestBody',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'clientIp',
    label: 'ClientIP',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'method',
    label: 'Method',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'scheme',
    label: 'Scheme',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'host',
    label: 'Host',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'path',
    label: 'Path',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'query',
    label: 'Query',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'referer',
    label: 'Referer',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'userAgent',
    label: 'UserAgent',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'cookie',
    label: 'Cookie',
    type: 'text',
  },
  {
    input: 'DatePicker',
    id: 'responseDatetimeStart',
    label: 'ResponseDatetimeStart',
    type: 'text',
  },
  {
    input: 'DatePicker',
    id: 'responseDatetimeEnd',
    label: 'ResponseDatetimeEnd',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'timeTakenMillSecondsMin',
    label: 'TimeTakenMillSecondsMin',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'timeTakenMillSecondsMax',
    label: 'TimeTakenMillSecondsMax',
    type: 'text',
  },
  {
    input: 'HttpStatusSelector',
    id: 'statuses',
    label: 'Statuses',
    type: 'custom',
  },
];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string | number | string[]
): SearchCondition => {
  switch (key) {
    case 'match':
      return { ...cond, match: value as string };
    case 'requestId':
      return { ...cond, requestId: value as string };
    case 'datetimeStart':
      if (!value) return cond;
      return { ...cond, datetimeStart: value as string };
    case 'datetimeEnd':
      if (!value) return cond;
      return { ...cond, datetimeEnd: value as string };
    case 'requestContentType':
      return { ...cond, requestContentType: value as string };
    case 'requestBody':
      return { ...cond, requestBody: value as string };
    case 'clientIp':
      return { ...cond, clientIp: value as string };
    case 'method':
      return { ...cond, method: value as string };
    case 'scheme':
      return { ...cond, scheme: value as string };
    case 'host':
      return { ...cond, host: value as string };
    case 'path':
      return { ...cond, path: value as string };
    case 'query':
      return { ...cond, query: value as string };
    case 'referer':
      return { ...cond, referer: value as string };
    case 'userAgent':
      return { ...cond, userAgent: value as string };
    case 'cookie':
      return { ...cond, cookie: value as string };
    case 'timeTakenMilliSecondsMin':
      return { ...cond, timeTakenMilliSecondsMin: value as number };
    case 'timeTakenMilliSecondsMax':
      return { ...cond, timeTakenMilliSecondsMax: value as number };
    case 'statuses':
      return { ...cond, statuses: value as string[] };
    case 'accountId':
      return { ...cond, accountId: value as number };
    case 'loginId':
      return { ...cond, loginId: value as string };
    case 'limit':
      return { ...cond, limit: value as number };
  }
};
