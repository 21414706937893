import React, { useState } from 'react';
import {
  SearchCondition,
  advancedSearchFields,
  changeSearchCondition,
  defaultSearchCondition,
} from '../../domain/Userbase/Account';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  /**
   * コントロール側の検索条件に変更を反映するインターフェース
   */
  updateSearchCondition: (data: SearchCondition) => void;
};

export const Search: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [advanced, setAdvanced] = useState(false);
  const [formCondition, setFormCondition] = useState(defaultSearchCondition);

  /**
   * 検索条件変更処理を実行する
   * @param e イベント
   */
  const handleChangeCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const cond = changeSearchCondition(
      formCondition,
      e.target.id as keyof SearchCondition,
      e.target.value
    );

    setFormCondition(cond);
  };

  /**
   * 検索を実行する
   * @param e イベント
   */
  const handleSubmitSearch = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    props.updateSearchCondition(formCondition);
  };

  /**
   * 詳細検索条件を表示・非表示する
   */
  const handleToggleAdvanced = () => {
    setAdvanced(!advanced);
  };

  return (
    <form onSubmit={handleSubmitSearch}>
      <TextField
        id="match"
        label="Keyword"
        type="text"
        value={formCondition.match}
        onChange={handleChangeCondition}
      />
      <Button type="submit" variant="contained">
        Search
      </Button>
      <div style={{ display: advanced ? 'block' : 'none' }}>
        {advancedSearchFields.map((d) => {
          const key = d.id as keyof SearchCondition;
          return (
            <TextField
              key={d.id}
              id={d.id}
              label={d.label}
              value={formCondition[key] || ''}
              type={d.type}
              onChange={handleChangeCondition}
            />
          );
        })}
      </div>
      <div>
        <Button
          variant="contained"
          className={classes.advancedSearchButton}
          onClick={handleToggleAdvanced}
        >
          Advanced Search {advanced ? '-' : '+'}
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  advancedSearchButton: {
    marginLeft: '10px',
  },
});
