export type AMI = {
  amiId: string;
  amiName: string;
  amiCreatedAt: string;
  monitoredAt: string;
  tags: Tag[];
};

export const defaultAMI: AMI = {
  amiId: '',
  amiName: '',
  amiCreatedAt: '',
  monitoredAt: '',
  tags: [],
};

export type Tag = {
  tagKey: string;
  tagValue: string;
};

export type SearchCondition = {
  match: string;
  limit: number;
  offset: number;
};

export const defaultSearchCondition: SearchCondition = {
  match: '',
  limit: 0,
  offset: 0,
};

/**
 * AMI監視の詳細検索フィールド
 */
export const advancedSearchFields = [];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string
): SearchCondition => {
  switch (key) {
    case 'match':
      return { ...cond, match: value };
    case 'limit':
      return { ...cond, limit: parseInt(value) };
    case 'offset':
      return { ...cond, offset: parseInt(value) };
  }
};
