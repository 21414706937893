import React, { useState } from 'react';
import clsx from 'clsx';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';
import Session from './store/session';
import { NotificationArea } from './components/NotificationArea';
import { DashboardControl } from './views/DashboardControl';
import { DomainControl } from './views/DomainControl';
import { AccountControl } from './views/AccountControl';
import { KeyControl } from './views/KeyControl';
import { AccessLogControl } from './views/AccessLogControl';
import { PostDataControl } from './views/PostDataControl';
import { ActionLogControl } from './views/ActionLogControl';
import { ErrorLogControl } from './views/ErrorLogControl';
import { NewsStackControl } from './views/NewsStackControl';
import { AMIControl } from './views/AMIControl';
import { InfraControl } from './views/InfraControl';
import { InfraTreeControl } from './views/InfraTreeControl';
import {
  CssBaseline,
  AppBar,
  Typography,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DomainIcon from '@material-ui/icons/Domain';
import PeopleIcon from '@material-ui/icons/People';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InputIcon from '@material-ui/icons/Input';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import PanToolIcon from '@material-ui/icons/PanTool';
import ErrorIcon from '@material-ui/icons/Error';
import LaunchIcon from '@material-ui/icons/Launch';
import DnsIcon from '@material-ui/icons/Dns';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { makeStyles } from '@material-ui/core/styles';
import { SelfSetting } from './domain/SelfSetting';

const EmptyCompornent: React.FC = () => {
  return <div />;
};

type Props = {
  /**
   * 設定
   */
  selfSetting: SelfSetting;
};

export const Navi: React.FC<Props> = (props) => {
  const authNavi = [
    {
      label: 'ダッシュボード',
      path: '/base/dashboard*',
      to: '/base/dashboard',
      route: DashboardControl,
      icon: 'Dashboard',
      external: false,
      visible: props.selfSetting.dashboard,
    },
    {
      label: 'ドメイン',
      path: '/base/domain*',
      to: '/base/domain',
      route: DomainControl,
      icon: 'Domain',
      external: false,
      visible: props.selfSetting.domain,
    },
    {
      label: 'アカウント',
      path: '/base/userbase-account*',
      to: '/base/userbase-account',
      route: AccountControl,
      icon: 'People',
      external: false,
      visible: props.selfSetting.account,
    },
    {
      label: '認証鍵',
      path: '/base/userbase-key*',
      to: '/base/userbase-key',
      route: KeyControl,
      icon: 'VpnKey',
      external: false,
      visible: props.selfSetting.key,
    },
    {
      label: 'セミナーモニター',
      path: '/gcmon/seminars',
      to: '/gcmon/seminars',
      route: EmptyCompornent,
      icon: 'Dashboard',
      external: true,
      visible: props.selfSetting.gcSeminarMonitor,
    },
    {
      label: 'インフラモニター',
      path: '/gcmon/infrastructure',
      to: '/gcmon/infrastructure',
      route: EmptyCompornent,
      icon: 'Dns',
      external: true,
      visible: props.selfSetting.gcInfraMonitor,
    },
    {
      label: 'アクセスログ',
      path: '/base/accesslog*',
      to: '/base/accesslog',
      route: AccessLogControl,
      icon: 'AirportShuttle',
      external: false,
      visible: props.selfSetting.accessLog,
    },
    {
      label: 'POSTログ',
      path: '/base/postdata*',
      to: '/base/postdata',
      route: PostDataControl,
      icon: 'Input',
      external: false,
      visible: props.selfSetting.postData,
    },
    {
      label: '操作ログ',
      path: '/base/actionlog*',
      to: '/base/actionlog',
      route: ActionLogControl,
      icon: 'PanTool',
      external: false,
      visible: props.selfSetting.actionLog,
    },
    {
      label: 'エラーログ',
      path: '/base/errorlog*',
      to: '/base/errorlog',
      route: ErrorLogControl,
      icon: 'Error',
      external: false,
      visible: props.selfSetting.errorLog,
    },
    {
      label: 'NewsStack',
      path: '/base/newsstack*',
      to: '/base/newsstack',
      route: NewsStackControl,
      icon: 'Announcement',
      external: false,
      visible: props.selfSetting.newsStack,
    },
    {
      label: 'AMIMonitor',
      path: '/base/inframonitor-ami*',
      to: '/base/inframonitor-ami',
      route: AMIControl,
      icon: 'Launch',
      external: false,
      visible: props.selfSetting.ami,
    },
    {
      label: 'InfraMonitor',
      path: '/base/inframonitor*',
      to: '/base/inframonitor',
      route: InfraControl,
      icon: 'Dns',
      external: false,
      visible: props.selfSetting.inframonitor,
    },
    {
      label: 'InfraTree',
      path: '/base/infratree*',
      to: '/base/infratree',
      route: InfraTreeControl,
      icon: 'Dns',
      external: false,
      visible: props.selfSetting.infratree,
    },
  ];

  const authLinks = authNavi
    .filter((d) => d.visible)
    .map((d) => {
      return (
        <Item
          label={d.label}
          path={d.path}
          to={d.to}
          icon={d.icon}
          external={d.external}
          key={d.label}
        />
      );
    });
  const authRoutes = authNavi
    .filter((d) => d.visible)
    .filter((d) => d.path.length > 0) // このモジュール外へのリンクはRoutesに入れない
    .map((d) => {
      return (
        <Route key={d.path} exact path={d.path} component={d.route}></Route>
      );
    });

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const handlerLogout = async () => {
    history.push('/logout');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {process.env.REACT_APP_SITE_TITLE}
          </Typography>
          <HeaderMenus />
          <Button color="inherit" onClick={handlerLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Router>
        <Drawer
          variant="permanent"
          anchor="left"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
        >
          <div style={{ display: Session.isLogin() ? 'block' : 'none' }}>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>{authLinks}</List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>{authRoutes}</Switch>
        </main>
      </Router>
    </div>
  );
};

type PropsItem = {
  label: string;
  path: string;
  to: string;
  icon: string;
  external: boolean;
};

const Item: React.FC<PropsItem> = (props) => {
  if (props.external) {
    // SPA外リンク
    return (
      <ListItem button component="a" href={props.to}>
        <SetIcon
          label={props.label}
          path={props.path}
          to={props.to}
          icon={props.icon}
          external={props.external}
        />
        <ListItemText primary={props.label} />
      </ListItem>
    );
  }
  // SPA内リンク
  return (
    <ListItem button component={Link} to={props.to}>
      <SetIcon
        label={props.label}
        path={props.path}
        to={props.to}
        icon={props.icon}
        external={props.external}
      />
      <ListItemText primary={props.label} />
    </ListItem>
  );
};

const SetIcon: React.FC<PropsItem> = (props) => {
  return (
    <ListItemIcon>
      {props.icon === 'Dashboard' && <DashboardIcon />}
      {props.icon === 'Domain' && <DomainIcon />}
      {props.icon === 'People' && <PeopleIcon />}
      {props.icon === 'VpnKey' && <VpnKeyIcon />}
      {props.icon === 'AirportShuttle' && <AirportShuttleIcon />}
      {props.icon === 'Input' && <InputIcon />}
      {props.icon === 'PanTool' && <PanToolIcon />}
      {props.icon === 'Error' && <ErrorIcon />}
      {props.icon === 'Launch' && <LaunchIcon />}
      {props.icon === 'Dns' && <DnsIcon />}
      {props.icon === 'Announcement' && <AnnouncementIcon />}
    </ListItemIcon>
  );
};

export const HeaderMenus: React.FC = () => {
  if (process.env.REACT_APP_NOTIFICATOIN_ON === 'true') {
    return <NotificationArea />;
  }
  return <div />;
};

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));
