import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Badge,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Paper,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import * as api from '../api/newsstack';
import { News, defaultSearchCondition } from '../domain/NewsStack';

export const NotificationArea: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const prevOpen = useRef(openMenu);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [news, setNews] = useState([] as News[]);

  const fetchNews = async () => {
    const data = await api.listMyNews(defaultSearchCondition);
    setNews(data);
  };

  const handleToggleMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (e: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }
    setOpenMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  useEffect(() => {
    fetchNews();

    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  return (
    <div>
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleMenu}
      >
        <Badge badgeContent={news.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper style={{ maxWidth: 400 }}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  autoFocusItem={openMenu}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {news.map((m, i) => {
                    return (
                      <MenuItem
                        key={m.notificationId}
                        style={{
                          borderTop: i !== 0 ? '#eee 1px solid' : '',
                          whiteSpace: 'inherit',
                        }}
                        onClick={() => {
                          if (m.linkUrl !== '') {
                            window.location.href = m.linkUrl;
                          }
                        }}
                      >
                        {m.message}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
