import { dateUtcFormat } from '../dateFormat';

export type Account = {
  accountId: number;
  loginId: string;
  email: string;
  familyName: string;
  givenName: string;
  beginDate: string | null;
  endDate: string | null;
  forceDisable: boolean;
  deleted: boolean;
  denyDelete: boolean;
};

export const defaultAccount: Account = {
  accountId: 0,
  loginId: '',
  email: '',
  familyName: '',
  givenName: '',
  beginDate: null,
  endDate: null,
  forceDisable: false,
  deleted: false,
  denyDelete: false,
};

export type AccountForm = {
  accountId: number;
  loginId: string;
  email: string;
  familyName: string;
  givenName: string;
  beginDate: string;
  endDate: string;
  forceDisable: boolean;
  deleted: boolean;
  denyDelete: boolean;
};

export const defaultAccountForm: AccountForm = {
  accountId: 0,
  loginId: '',
  email: '',
  familyName: '',
  givenName: '',
  beginDate: '',
  endDate: '',
  forceDisable: false,
  deleted: false,
  denyDelete: false,
};

export type SearchCondition = {
  match: string;
  enableStatus: boolean | null;
  beginDateStart: string | null;
  beginDateEnd: string | null;
  endDateStart: string | null;
  endDateEnd: string | null;
  forceDisable: boolean | null;
  denyDelete: boolean | null;
  limit: number;
  offset: number;
};

export const defaultSearchCondition: SearchCondition = {
  match: '',
  enableStatus: null,
  beginDateStart: null,
  beginDateEnd: null,
  endDateStart: null,
  endDateEnd: null,
  forceDisable: null,
  denyDelete: null,
  limit: 50,
  offset: 0,
};

/**
 * アカウントの詳細検索フィールド
 */
export const advancedSearchFields = [
  {
    input: 'TextField',
    id: 'enableStatus',
    label: 'enableStatus',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'beginDateStart',
    label: 'BeginDateStart',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'beginDateEnd',
    label: 'BeginDateEnd',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'endDateStart',
    label: 'EndDateStart',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'endDateEnd',
    label: 'EndDateEnd',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'forceDisable',
    label: 'ForceDisable',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'denyDelete',
    label: 'DenyDelete',
    type: 'text',
  },
  {
    input: 'TextField',
    id: 'limit',
    label: 'Limit',
    type: 'number',
  },
  {
    input: 'TextField',
    id: 'offset',
    label: 'Offset',
    type: 'number',
  },
];

/**
 * 画面上で変更されたら、検索条件オブジェクトを更新する
 */
export const changeSearchCondition = (
  cond: SearchCondition,
  key: keyof SearchCondition,
  value: string
): SearchCondition => {
  switch (key) {
    case 'match':
      return { ...cond, match: value };
    case 'enableStatus':
      return { ...cond, enableStatus: null };
    case 'beginDateStart':
      return { ...cond, beginDateStart: dateUtcFormat(value) };
    case 'beginDateEnd':
      return { ...cond, beginDateEnd: dateUtcFormat(value) };
    case 'endDateStart':
      return { ...cond, endDateStart: dateUtcFormat(value) };
    case 'endDateEnd':
      return { ...cond, endDateEnd: dateUtcFormat(value) };
    case 'forceDisable':
      return { ...cond, forceDisable: true };
    case 'denyDelete':
      return { ...cond, denyDelete: true };
    case 'limit':
      return { ...cond, limit: parseInt(value) };
    case 'offset':
      return { ...cond, offset: parseInt(value) };
  }
};
