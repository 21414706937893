import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const DashboardControl: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2>Welcome to {process.env.REACT_APP_SITE_TITLE}.</h2>
      <p>
        ダッシュボードはまだ実装していません。たぶん以下のような画面になります
      </p>
      <ul>
        <li>InfraTreeを表示</li>
        <li>システム利用状況の概要表示</li>
      </ul>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
