import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Session from '../store/session';
import { Error, useDispatch } from '../store/provider';
import * as userbaseAPI from 'api/userbase';

const Logout: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      (async () => await userbaseAPI.logout())();

      Session.unsetLogin();
      history.push('/login');
    } catch (e) {
      console.error(e);
      Error(dispatch, 'ログアウトに失敗しました');
    }
  }, [history, dispatch]);

  return <></>;
};

export default withRouter(Logout);
