import React, { useState } from 'react';
import {
  SearchCondition,
  advancedSearchFields,
  changeSearchCondition,
} from '../../domain/AccessLog';
import { DatePicker } from 'components/molecules/DatePicker';
import { HttpStatusSelector } from '../molecules/HttpStatusSelector';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

type Props = {
  /**
   * 検索条件の変更のインターフェース
   */
  data: SearchCondition;
  updateSearchCondition: (data: SearchCondition) => void;
  executeSearch: (data: SearchCondition) => Promise<void>;
};

export const Search: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [advanced, setAdvanced] = useState(false);

  /**
   * 検索条件変更処理を実行する
   */
  const changeCondition = (
    key: keyof SearchCondition,
    value: string | string[] | number
  ) => {
    const cond = changeSearchCondition(props.data, key, value);
    props.updateSearchCondition(cond);
  };

  /**
   * 検索条件変更イベントを取得して、実行処理に渡す
   * @param e
   */
  const handleChangeCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    changeCondition(e.target.id as keyof SearchCondition, e.target.value);
  };

  /**
   * 検索を実行する
   * @param e イベント
   */
  const handleSubmitSearch = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    props.executeSearch(props.data);
  };

  /**
   * 詳細検索条件を表示・非表示する
   */
  const handleToggleAdvanced = () => {
    setAdvanced(!advanced);
  };

  return (
    <form onSubmit={handleSubmitSearch}>
      <TextField
        id="match"
        label="Keyword"
        type="text"
        value={props.data.match}
        onChange={handleChangeCondition}
      />
      <DatePicker
        id="datetimeStart"
        label="DatetimeStart"
        value={dayjs(props.data.datetimeStart)}
        onChange={changeCondition}
      />
      <DatePicker
        id="datetimeEnd"
        label="DatetimeEnd"
        value={dayjs(props.data.datetimeEnd)}
        onChange={changeCondition}
      />
      <Button type="submit" variant="contained">
        Search
      </Button>
      <div style={{ display: advanced ? 'block' : 'none' }}>
        {advancedSearchFields.map((d) => {
          const key = d.id as keyof SearchCondition;
          switch (d.input) {
            case 'TextField':
              return (
                <TextField
                  key={key}
                  id={key}
                  label={d.label}
                  value={props.data[key]}
                  type={d.type}
                  onChange={handleChangeCondition}
                />
              );
            case 'HttpStatusSelector':
              return (
                <HttpStatusSelector
                  key={key}
                  id={key}
                  label={d.label}
                  value={props.data[key] as string[]}
                  onChange={changeCondition}
                />
              );
          }
          return <div />;
        })}
      </div>
      <div>
        <Button
          variant="contained"
          className={classes.advancedSearchButton}
          onClick={handleToggleAdvanced}
        >
          Advanced Search {advanced ? '-' : '+'}
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  advancedSearchButton: {
    marginLeft: '10px',
  },
});
