import { post } from './io';
import {
  News,
  SearchCondition as NewsSearch,
  NewsForm,
} from '../domain/NewsStack';
import { dateUtcFormat } from '../domain/dateFormat';
import dayjs from 'dayjs';

const rootUrl =
  document.location.protocol + '//' + document.location.host + '/base_api';

export const listMyNews = async (params: NewsSearch): Promise<Array<News>> => {
  const url = rootUrl + '/newsstack/user/list';
  const ret: News[] = await post(url, params);
  return Promise.resolve(ret);
};

export const listNews = async (params: NewsSearch): Promise<Array<News>> => {
  const url = rootUrl + '/newsstack/list';
  const ret: News[] = await post(url, params);
  return Promise.resolve(ret);
};

export const describeNews = async (notificationId: number): Promise<News> => {
  const params = {
    notificationId,
  };
  const url = rootUrl + '/newsstack/describe';
  const ret: News = await post(url, params);
  return Promise.resolve(ret);
};

export const addNews = async (news: NewsForm): Promise<News> => {
  const url = rootUrl + '/newsstack/add';
  const data: News = {
    notificationId: 0,
    context: news.context,
    message: news.message,
    linkUrl: news.linkUrl,
    notifiedAt: dayjs().toISOString(),
    accountIds: [1, 2],
  };
  const params = {
    data,
  };
  const ret = await post(url, params);

  return Promise.resolve(ret);
};

export const updateNews = async (news: NewsForm): Promise<News> => {
  const url = rootUrl + '/newsstack/update';
  const data: News = {
    notificationId: news.notificationId,
    context: news.context,
    message: news.message,
    linkUrl: news.linkUrl,
    notifiedAt: dateUtcFormat(news.notifiedAt) || '',
    accountIds: [1],
  };
  const params = {
    data,
  };
  const ret = await post(url, params);

  return Promise.resolve(ret);
};

export const deleteNews = async (notificationId: number): Promise<void> => {
  const url = rootUrl + '/newsstack/delete';
  const params = {
    notificationId,
  };
  await post(url, params);
  return Promise.resolve();
};
