import React from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useGlobalState } from "../store/provider";

const Alert = (props: AlertProps) => {
  return <MuiAlert {...props}></MuiAlert>;
};

export const MessageArea: React.FC = () => {
  const classes = useStyles();
  const state = useGlobalState("messages");

  const alerts = state.map((m, i) => {
    return (
      <Alert severity={m.severity} className={classes.alert} key={i}>
        {m.text}
      </Alert>
    );
  });
  return <div className={classes.alertArea}>{alerts}</div>;
};

const useStyles = makeStyles((theme) => ({
  alertArea: {
    position: "fixed",
    zIndex: 9999,
    top: 64,
    right: 0,
  },
  alert: {
    marginBottom: "2px",
  },
}));
