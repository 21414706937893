import React from 'react';
import { Account } from '../../domain/Userbase/Account';
import { dateFormat } from '../../domain/dateFormat';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

type Props = {
  /**
   * アカウント一覧の表示データ
   */
  data: Account[];

  /**
   * 詳細情報を表示するためのインターフェース
   */
  openDetail: (accountId: number) => void;
};

export const List: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">AccountId</TableCell>
              <TableCell align="right">LoginId</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Mail</TableCell>
              <TableCell align="right">ActiveState</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((d, i) => (
              <TableRow
                key={d.loginId}
                hover
                onClick={() => props.openDetail(props.data[i].accountId)}
              >
                <TableCell component="th" scope="row">
                  {d.accountId}
                </TableCell>
                <TableCell align="right">{d.loginId}</TableCell>
                <TableCell align="right">
                  {d.familyName} {d.givenName}
                </TableCell>
                <TableCell align="right">{d.email}</TableCell>
                <TableCell align="right">
                  ValidFrom: {dateFormat(d.beginDate)}
                  <br />
                  ValidTo:{dateFormat(d.endDate)}
                  <br />
                  forceDisable: {d.forceDisable.toString()}
                  <br />
                  denyDelete: {d.denyDelete.toString()}
                  <br />
                  deleted: {d.deleted.toString()}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => props.openDetail(props.data[i].accountId)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
