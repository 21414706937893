import React, { useCallback, useEffect } from 'react';
import Session from 'store/session';
import { useHistory } from 'react-router-dom';
import * as api from 'api/userbase';

/**
 * トップページURLにアクセスしたときの、以下の制御を行う
 *
 * 1. 非ログイン時はログイン画面に遷移する。
 * 2. ログイン時は予め設定した、いずれかの画面に遷移する。
 */
export const TopRedirect: React.FC = () => {
  const history = useHistory();

  /**
   * 認証が必要なAPIを叩いてログイン確認
   */
  const redirect = useCallback(async () => {
    if (!Session.isLogin) {
      history.push('/login');
    }

    try {
      // 認証確認できた場合
      await api.verify;
      window.location.href = '/gcmon/seminars';
    } catch {
      // 何かしらエラーがあった場合
      history.push('/login');
      return;
    }
  }, [history]);

  useEffect(() => {
    redirect();
  }, [history, redirect]);

  return <div />;
};
