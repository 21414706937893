import React from 'react';
import { ErrorLog } from '../../domain/ErrorLog';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DateFormat } from '../../domain/Format';

type Props = {
  /**
   * 詳細情報データ
   */
  data: ErrorLog;

  /**
   * 詳細情報を非表示にするためのインターフェース
   */
  closeDetail: () => void;
};

export const Detail: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const d = props.data;

  if (d.requestId === '') {
    return <div />;
  }

  return (
    <Card className={classes.container}>
      <CardHeader
        style={{ background: '#eee' }}
        action={
          <IconButton aria-label="settings" onClick={props.closeDetail}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List>
        <ListItem>
          <div className={classes.attrLabel}>Product</div>
          <div>{d.product}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Function</div>
          <div>{d.function}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>RequestID</div>
          <div>{d.requestId}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>URL</div>
          <div>{d.url}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Level</div>
          <div>{d.level}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Code</div>
          <div>{d.code}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>Description</div>
          <div>{d.description}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>OccurredAt</div>
          <div> {DateFormat(d.occurredAt)}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>CustomerDomain</div>
          <div>{d.customerDomain}</div>
        </ListItem>
        <ListItem>
          <div className={classes.attrLabel}>CustomerName</div>
          <div>{d.customerName}</div>
        </ListItem>
      </List>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: '720px',
    width: '30%',
    position: 'fixed',
    top: 85,
    right: 0,
    marginRight: 0,
    bottom: '10px',
  },
  attrLabel: {
    textAlign: 'right',
    width: '30%',
    marginRight: '10px',
    wordBreak: 'break-all',
  },
  attrValue: {
    width: '70%',
    wordBreak: 'break-all',
  },
});
