import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  IconButton,
  Modal,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { NewsForm } from '../../domain/NewsStack';
import * as api from '../../api/newsstack';
import { Success, useDispatch } from '../../store/provider';

type Props = {
  /**
   * 編集対象となるお知らせデータ
   */
  data: NewsForm;

  /**
   * 一覧更新処理へのインターフェース
   */
  reloadList: () => void;

  /**
   * 編集した内容をお知らせデータに反映するためのインターフェース
   */
  changeEdit: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * 編集ダイアログを閉じる処理へのインターフェース
   */
  closeEdit: () => void;
};

export const Edit: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await api.updateNews(props.data);
    Success(dispatch, 'updated');
    props.reloadList();
    props.closeEdit();
  };

  const handleDelete = async (e: React.MouseEvent) => {
    if (!window.confirm('ok?')) {
      return;
    }
    await api.deleteNews(props.data.notificationId);
    Success(dispatch, 'deleted');
    props.reloadList();
    props.closeEdit();
  };

  let form = <div />;
  if (props.data.notificationId > 0) {
    form = (
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.paper}>
          <CardHeader
            style={{ background: '#eee' }}
            action={
              <div aria-label="settings">
                <IconButton onClick={props.closeEdit}>
                  <CloseIcon />
                </IconButton>
              </div>
            }
          />
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              name="message"
              label="Message"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.message}
            />
            <TextField
              name="linkUrl"
              label="LinkURL"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.linkUrl}
            />
            <TextField
              name="notifiedAt"
              label="NotifiedAt"
              fullWidth
              onChange={props.changeEdit}
              value={props.data.notifiedAt}
            />
            <CardActions>
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>
    );
  }

  return form;
};

const dialogWidth = 600;
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: dialogWidth,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      top: '10%',
      left: 'calc(50% - 300px)',
    },
    form: {
      padding: theme.spacing(2, 4, 3),
    },
  })
);
