import React, { useState } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { Datetime } from 'domain/dateFormat';

interface Props<T> {
  value: Datetime;
  id: keyof T;
  label: string;
  required?: boolean;
  onChange: (id: keyof T, value: any) => void;
}

type DatePickerType = <T>(props: Props<T>) => React.ReactElement<Props<T>>;

/**
 * @material-ui/pickersのラッパー。
 * day.jsを使うことが前提。
 * @param props
 */
export const DatePicker: DatePickerType = (props) => {
  /**
   * エラー状態の制御
   */
  const [error, setError] = useState({
    error: false,
    helperText: '',
  });

  /**
   * DatePickerのvalueの変化を、親コンポーネントに伝える
   * @param day dayjsオブジェクト
   */
  const handleDateChange = (day: Datetime | null) => {
    try {
      props.onChange(props.id, day?.toISOString());
      setError({ error: false, helperText: '' });
    } catch {
      props.onChange(props.id, '');
      setError({ error: true, helperText: '日付を入力してください' });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDatePicker
        disableToolbar
        value={props.value}
        label={props.label}
        required={props.required}
        format="YYYY/MM/DD"
        variant="inline"
        error={error.error}
        helperText={error.helperText}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};
