import { post } from './io';
import { ErrorLog, SearchCondition } from '../domain/ErrorLog';
import dayjs from 'dayjs';

const rootUrl = document.location.protocol + '//' + document.location.host;

export const list = async (cond: SearchCondition): Promise<Array<ErrorLog>> => {
  const url = rootUrl + '/base_api/errorlog/list';
  const params = {
    ...cond,
    occurredAtEnd: dayjs(cond.occurredAtEnd).add(1, 'day').toISOString(),
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const describe = async (id: number): Promise<ErrorLog> => {
  const url = rootUrl + '/base_api/errorlog/describe';
  const params = {
    id,
  };
  const ret = await post(url, params);
  return Promise.resolve(ret);
};

export const add = async (log: ErrorLog): Promise<ErrorLog> => {
  const url = rootUrl + '/base_api/errorlog/add';

  const params = {
    actionlog: log,
  };
  const ret = await post(url, params);

  return Promise.resolve(ret);
};
